import _ from "lodash";
import PropTypes from "prop-types";
import * as ReactDOM from "react-dom";
import React, { PureComponent } from "react";

import scrollTo from "scroll-to";
import LoadingBlock from "../../LoadingBlock";
import SettingsSection from "../../SettingsSection";
import NavigationSidebar from "../../refactored/NavigationSidebar/NavigationSidebar";
import "../../../css/deprecated/toolbar.css";
// eslint-disable-next-line no-unused-vars
import scrollNavigator from "./css/scroll-navigator.css";
import MultiSettingSection from "../../MultiSettingsSection";

const FOOTER_HEIGHT = 125;
const LAST_BLOCK_MIN_HEIGHT = 232;

class ScrollNavigator extends PureComponent {
  static propTypes = {
    navigationBlocks: PropTypes.array,
    title: PropTypes.string,
    hiddenSidebarForSmallDisplay: PropTypes.bool,
    onRefresh: PropTypes.func,
    isReverseScrollNavigatorBlocks: PropTypes.bool,
    isAddNumberBlockToSidebar: PropTypes.bool,
    additionalSidebarBlock: PropTypes.any,
  };

  static defaultProps = {
    isReverseScrollNavigatorBlocks: false,
    isAddNumberBlockToSidebar: false,
  };

  blockRefList = [];

  state = {
    paddingBottom: undefined,
    containerBlockWidth: 0,
  };

  recalculatePadding = _.debounce(() => {
    this.setStyleToBlock();
    this.setState({ containerBlockWidth: this.containerBlock?.clientWidth });
  }, 500);

  componentDidMount() {
    const { scrollToPosition, scrollTo } = this.props;
    this.setStyleToBlock();
    window.addEventListener("resize", this.recalculatePadding);
    this.containerBlock && this.containerBlock.addEventListener("resize", this.recalculatePadding);
    scrollTo ? this.scrollToNeededItem() : scrollToPosition && window.scrollTo(scrollToPosition.x, scrollToPosition.y);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.recalculatePadding);
    this.containerBlock && this.containerBlock.removeEventListener("resize", this.recalculatePadding);
    this.recalculatePadding.cancel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollTo !== this.props.scrollTo) {
      this.scrollToNeededItem();
    }
  }

  setStyleToBlock = () => {
    const { maxWidth, notDisplaySidebar } = this.props;
    const paddingBottom = this.generatePadding();

    if (!notDisplaySidebar) {
      const scrollNavigator = this.scrollNavigator;
      scrollNavigator.setAttribute("style", `padding-bottom: ${paddingBottom}px; flex-basis: ${maxWidth}px`);
    }
  };

  generatePadding = () => {
    const positionSidebar = this.sidebar && this.sidebar.getBoundingClientRect().y;
    const padding = window.innerHeight - positionSidebar - LAST_BLOCK_MIN_HEIGHT - FOOTER_HEIGHT;
    return padding;
  };

  getLastNavigationBlockId = () => {
    const { navigationBlocks } = this.props;
    const lastElementIndex = navigationBlocks.length - 1;
    const lastNavigationBlock = navigationBlocks[lastElementIndex];
    const lastNavigationBlockId =
      lastNavigationBlock.hiddenBlock && lastNavigationBlock.hiddenBlock.isShowBlock
        ? lastNavigationBlock.hiddenBlock.id
        : lastNavigationBlock.id;
    return lastNavigationBlockId;
  };

  handleSidebar = id => {
    const posY = this.calculatePositionY(id);
    scrollTo(0, this.props.editBlockIsOpen ? 0 : posY, {
      duration: 200,
      behavior: "smooth",
    });
  };

  calculatePositionY = id => {
    const { y } = this.blockRefList[id] ? this.blockRefList[id].getBoundingClientRect() : 0;
    const positionSidebar = this.sidebar?.getBoundingClientRect()?.y;
    return window.pageYOffset + (y - (positionSidebar ? positionSidebar : 160));
  };

  scrollToNeededItem = () => {
    const { onClearScrollItem } = this.props;
    const { scrollTo: id } = this.props;
    if (!!id && id !== "") {
      this.handleSidebar(id);
      onClearScrollItem && onClearScrollItem();
    }
  };

  setContainerBlockRef = ref => {
    const width = ref?.clientWidth;
    this.containerBlock = ref;

    this.setState({ containerBlockWidth: width });
  };

  renderSection = block => {
    const {
      id,
      text,
      component,
      width,
      sectionWrapper,
      notDisplay,
      isLoading,
      question,
      counter,
      toolbar,
      titleColor,
      getRefTitle,
      questionLink,
      handlerDragLeave,
      handlerDragEnter,
      handleDrop,
      isShowPopover,
      rightTooltip,
      createReport,
      disableReport,
      handleReportRequest,
    } = block;
    return (
      !notDisplay &&
      (sectionWrapper ? (
        <SettingsSection
          key={id}
          id={id}
          sectionRef={block => {
            if (block) {
              this.blockRefList[id] = block;
            }
          }}
          title={text}
          rightTooltip={rightTooltip && rightTooltip}
          handlerDragLeave={handlerDragLeave}
          handlerDragEnter={handlerDragEnter}
          handleDrop={handleDrop}
          question={question}
          questionLink={questionLink}
          counter={counter}
          toolbar={toolbar}
          titleColor={titleColor}
          isShowPopover={isShowPopover}
          width={width}
          getRefTitle={getRefTitle}
          createReport={createReport}
          disableReport={disableReport}
          handleReportRequest={handleReportRequest}
        >
          {isLoading ? <LoadingBlock /> : component({})}
        </SettingsSection>
      ) : (
        component({
          key: id,
          ref: block => {
            this.blockRefList[id] = ReactDOM.findDOMNode(block);
          },
          scrollToSidebar: () => {
            this.handleSidebar(id);
          },
        })
      ))
    );
  };

  renderMultiSection = block => {
    const {
      id,
      components,
      hiddenBlock,
      containerStyleName,
      itemsStyleNames,
      isShowPopover,
      multiSettingSectionStyle,
    } = block;
    const hiddenBlockId = hiddenBlock && hiddenBlock.id;
    const isShowHiddenBlock = hiddenBlock && hiddenBlock.isShowBlock;

    return (
      <MultiSettingSection
        key={id}
        id={id}
        multiSettingSectionStyle={multiSettingSectionStyle}
        containerStyleName={containerStyleName}
        itemsStyleNames={itemsStyleNames}
        sectionRef={components => {
          if (components) {
            this.blockRefList[id] = components;
          }
        }}
        components={components || [block]}
        hiddenBlock={hiddenBlock}
        isShowPopover={isShowPopover}
        hiddenSectionRef={hiddenBlock => {
          if (hiddenBlock && isShowHiddenBlock) {
            this.blockRefList[hiddenBlockId] = hiddenBlock;
          }
        }}
      />
    );
  };

  render() {
    const {
      navigationBlocks,
      hiddenSidebarForSmallDisplay,
      additionalSidebarBlock,
      isReverseScrollNavigatorBlocks,
      isAddNumberBlockToSidebar,
      beforeBlockElement,
      sideBarCustomStyle,
      notDisplaySidebar,
      sidebarTop,
      hideNavigationSidebar,
      style,
      isUnauth,
    } = this.props;
    const { activeSectionId } = this.state;
    const sidebarStyle = hiddenSidebarForSmallDisplay
      ? "scrollNavigator.adaptive-hidden"
      : this.props.sideBarMobile
        ? "scrollNavigator.sidebarMobile"
        : "scrollNavigator.sidebar";
    const rowColumnStyle =
      additionalSidebarBlock && this.containerBlock && this.containerBlock.clientWidth < 640
        ? "scrollNavigator.row-column"
        : "";
    const containerStyle = `scrollNavigator.container ${
      isReverseScrollNavigatorBlocks ? "scrollNavigator.row-reverse" : ""
    } ${rowColumnStyle}`;
    const shouldShowMobileAdditionalBlock = isUnauth
      ? this.state.containerBlockWidth < 975
      : this.state.containerBlockWidth < 895;

    const mobileAdditionalBlock = shouldShowMobileAdditionalBlock ? (
      <div
        styleName={isUnauth ? "scrollNavigator.additionalMobileBlockUnauth" : "scrollNavigator.additionalMobileBlock"}
      >
        {additionalSidebarBlock}
      </div>
    ) : (
      ""
    );
    const styleSidebar = notDisplaySidebar ? { display: "none" } : {};
    return (
      //todo с появлением навигашки справа, нужно разворачивать марджин. Слишком много вариантов стилей сайдбара. Нужно как-то их зауниверсалить
      <div styleName={containerStyle} ref={this.setContainerBlockRef} style={style}>
        {!hideNavigationSidebar && (
          <div styleName={sideBarCustomStyle ? sideBarCustomStyle : sidebarStyle} style={styleSidebar}>
            <NavigationSidebar
              ref={node => {
                if (node) {
                  this.sidebar = ReactDOM.findDOMNode(node);
                }
              }}
              blockRefList={this.blockRefList}
              isError={false}
              activeId={activeSectionId}
              itemList={navigationBlocks}
              editBlockIsOpen={this.props.editBlockIsOpen}
              sideBarMobile={this.props.sideBarMobile}
              additionalSidebarBlock={additionalSidebarBlock}
              isAddNumberBlockToSidebar={isAddNumberBlockToSidebar}
              onChange={this.handleSidebar}
              sidebarTop={sidebarTop}
            />
          </div>
        )}
        <div
          styleName={"scrollNavigator.blocksMobile"}
          ref={scrollNavigator => (this.scrollNavigator = scrollNavigator)}
        >
          {beforeBlockElement && beforeBlockElement}
          {this.props.navigationBlocks.map(block => {
            const { multiSection } = block;
            return multiSection ? this.renderMultiSection(block) : this.renderSection(block);
          })}
          {additionalSidebarBlock && mobileAdditionalBlock}
        </div>
      </div>
    );
  }
}

export default ScrollNavigator;
