export const localeFormSelectStyle = (menuIsOpen, minBlocks = false) => {
  const controlBorder = menuIsOpen ? "1px solid #1E88E5" : "1px solid #CECECE";
  return {
    control: base => ({
      ...base,
      height: 32,
      minHeight: 32,
      minWidth: 286,
      maxWidth: 300,
      border: controlBorder,
      borderRadius: 1,
      boxShadow: "none",
      "&:focus": {
        border: "1px solid #1E88E5",
      },
    }),

    placeholder: base => ({
      ...base,
      color: "#9E9E9E",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 16,
    }),

    input: base => ({
      ...base,
    }),

    valueContainer: base => ({
      ...base,
      height: 30,
    }),

    singleValue: base => ({
      ...base,
      color: "#424242",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "16px",
      marginLeft: 1,
    }),

    dropdownIndicator: () => ({
      paddingRight: 1,
      backgroundImage:
        "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDx0aXRsZT5CM0MyNTgzMC02RDk1LTQ2NzYtQUI3My02OTgxRjAxREZBNjI8L3RpdGxlPiA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+IDxnPiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+IDwvZz4gPGc+ICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+ICA8ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9Im5vbmUiIGlkPSJoZWFkZXIiPiAgIDxnIGlkPSJwcm9maWxlLWFyZWEiIGZpbGw9IiNCREJEQkQiPiAgICA8ZyBpZD0ic3ZnXzEiPiAgICAgPGcgaWQ9InByb2ZpbGUtY29udGVudCI+ICAgICAgPGcgaWQ9IlRyaWFuZ2xlIj4gICAgICAgPHBvbHlnb24gZmlsbD0iIzYxNjE2MSIgaWQ9InN2Z18yIiBwb2ludHM9IjUsNiAwLDAgMTAsMCAiLz4gICAgICA8L2c+ICAgICA8L2c+ICAgIDwvZz4gICA8L2c+ICA8L2c+IDwvZz48L3N2Zz4=) center no-repeat",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    clearIndicator: base => ({
      ...base,
      display: "none",
    }),

    menu: base => ({
      ...base,
      marginTop: 0,
      borderRadius: 1,
      width: "auto",
      border: "1px solid #0075FF",
      borderTop: "none",
    }),

    menuList: base => ({
      ...base,
      padding: 0,
      boxShadow: "0 3px 4px 0 rgba(0,0,0,0.19)",
      maxHeight: minBlocks ? "300px" : "480px",
    }),

    option: base => ({
      ...base,
      width: 298,
      padding: 0,
      backgroundColor: "#FFFFFF",
      color: "#424242",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#E3F2FD",
        color: "#1E88E5",
      },
    }),
  };
};
