import React from "react";
// eslint-disable-next-line no-unused-vars
import editBlock from "../components/refactored/PaymentEditBlock/css/payment-edit-block.css";
import { formatLastSignIn } from "./formatLastSignIn";
import { I18n } from "react-redux-i18n";
import Button from "../components/Button/Button";
import iconSet from "../images/selection.json";
import IcomoonReact from "icomoon-react";

export const formatCompletedPayout = (payout, processPayout) => {
  if (payout.completed && payout.status === "COMPLETED") {
    return formatLastSignIn(payout.completed);
  } else if (!payout.completed && payout.paymentId !== 0) {
    return "Processing";
  } else if (payout.status === "FAILED") {
    return (
      <div className={editBlock.payoutFailedWrapper}>
        Failed
        <IcomoonReact
          iconSet={iconSet}
          color={"#545454"}
          size={15}
          icon={"restart"}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            return processPayout();
          }}
        />
      </div>
    );
  } else {
    return (
      <Button
        type="button"
        label={I18n.t("ADMIN_APP.PAYMENTS_PAGE.EDIT_PAYOUT_SECTION.BUTTONS.PROCESS")}
        buttonColor="green"
        buttonScheme="secondary"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          return processPayout();
        }}
      />
    );
  }
};
