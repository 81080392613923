import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import { FormLabel } from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import checkbox from "./css/form-checkbox.css";
import Loading from "../../Loading";
import Question from "../Title/components";

const INPUT_VALID = "INPUT_VALID";

export default class FormCheckbox extends Component {
  onChange = e => {
    this.props.onChange(e);
    document.activeElement.blur();
  };

  render() {
    let { id, label, name, loading, question } = this.props;
    const labelStyle =
      this.props.status === INPUT_VALID || this.props.status === undefined || this.props.status === "EMPTY_CURRENCY"
        ? "checkbox.label"
        : "checkbox.invalid";
    const inputStyle = this.props.typeCircle ? "checkbox.inputCircle" : "checkbox.input";
    let validationMessage;
    if (this.props.status === "INPUT_REQUIRED") {
      validationMessage = "INPUT_VALIDATIONS.REQUIRED";
    } else if (this.props.status === "EMPTY_CURRENCY") {
      validationMessage = "INPUT_VALIDATIONS.EMPTY_CURRENCY";
    }

    return (
      <div styleName={"checkbox.container"} style={this.props.containerStyle}>
        <div>
          <input
            type="checkbox"
            value={this.props.value}
            //todo: defaultChecked={} for uncontrolled, checked={} for controlled
            defaultChecked={this.props.value}
            checked={this.props.value}
            id={id}
            name={name}
            onClick={this.onChange}
            onChange={() => {}}
            styleName={inputStyle}
          />
          {!loading ? (
            <label
              tabIndex="-1"
              styleName={labelStyle}
              htmlFor={id}
              style={this.props.typeCircle && { borderRadius: 12 }}
            />
          ) : (
            <Loading display={loading} />
          )}
          <FormLabel styleName={"checkbox.control-label"}>
            {label}
            {question && (
              <span style={{ margin: "0 0 2px 5px", display: "inline-block", verticalAlign: "middle" }}>
                <Question question={question} />
              </span>
            )}
          </FormLabel>
        </div>
        {validationMessage && this.props.showValidateMessage && (
          <p styleName={"checkbox.validation-message"}>{I18n.t(validationMessage)}</p>
        )}
      </div>
    );
  }
}
