// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message__content__bWiUuh {
  white-space: pre-wrap;
  color: #212121;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  border: 1px solid #ffcc80;
  border-top: 0;
  border-radius: 0 0 1px 1px;
  background-color: #ffffff;
  /*width: 100%;*/
  font-family: "Helvetica";
  position: relative;

  height: auto;
  display: grid;
  grid-template-rows: minmax(0px, auto) minmax(67px, auto) 40px;
  min-height: 122px;
  max-height: 100%;
  max-height: calc(100vh - 290px);
}

.message__content-borders__bAtf2J {
  border-width: 0 1px !important;
  height: 100%;
  max-height: 100%;
}

.message__content-borders__bAtf2J .message__message-container__Kwevdv > div > div:first-child {
  border-top: 1px solid #ffcc80;
}

.message__content-borders__bAtf2J .message__message-container__Kwevdv > div > div:last-child {
  border-bottom: 1px solid #ffcc80;
}

@media screen and (min-width: 1400px) {
  .message__content__bWiUuh {
    max-height: calc(100vh - 216px);
  }
}

@media screen and (max-width: 1699.99px) {
  .message__content-translator__GMt9xR {
    max-height: calc(100vh - 290px);
  }
}

@media screen and (min-width: 1700px) {
  .message__content-translator__GMt9xR {
    max-height: calc(100vh - 216px);
  }
}

.message__message-block__h_pKKK {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-height: calc(100vh - 368px);
}

.message__message-container__Kwevdv {
  position: relative;
  min-height: 0;
  max-height: 100%;
  width: 100%;
  display: flex;
  overflow: initial;
  z-index: 0;
}

.message__message-container__Kwevdv > div:first-child {
  width: calc(100% + 17px);
}

.message__message-container__Kwevdv:not(:hover) > div:last-child {
  opacity: 0;
}

.message__input-block__KSchDW::-webkit-scrollbar {
  width: 5px;
  border-radius: 2.5px;
  background: #fff;
}

.message__input-block__KSchDW::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 2.5px;
}

.message__question__Tq6eea {
  height: 15px;
  width: 15px;
  min-width: 15px;
  display: flex;
  cursor: pointer;
  color: #212121;
  margin-left: 6px;
}

.message__thumb-vertical__JLYjlo {
  background: #e0e0e0;
  border-radius: 2.5px;
  width: 5px;
}

.message__input-container__ZGjvIe {
  position: relative;
  overflow: initial;
  border-top: 1px solid #fdcf8b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 66px;
  max-height: 132px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
}

.message__input-block__KSchDW {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  max-height: 132px;
}

.message__content-empty__QR70eb {
  padding: 16px;
  min-height: 126px;
  white-space: pre-wrap;
  color: #212121;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  border-radius: 0 0 1px 1px;
  background-color: #ffffff;
  font-family: "Helvetica";
}

.message__button-panel__vXhc24 {
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  padding: 6px 16px 10px;
  flex-direction: row;
  align-items: flex-end;
}

.message__button-container__mAj45U {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 1px;
  height: 24px;
  width: 100%;
}

.message__control-buttons-container__rmxLQH {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 1px;
  height: 24px;
}

.message__clear__rqDL9_ {
  cursor: pointer;
  text-align: center !important;
  color: #9e9e9e;
  font-size: 14px;
  line-height: 24px;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  display: inline-block;
  margin-right: 16px;
}

.message__clear__rqDL9_:active {
  outline: none !important;
}

.message__something-send-btn__MKyrgI {
  text-align: center !important;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #ff9800 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  background-color: #ffffff !important;
  color: #ff9800 !important;
  line-height: 16px !important;
  padding: 3px 15px 4px 15px !important;
}

.message__something-send-btn__MKyrgI:hover,
  .message__something-send-btn__MKyrgI:active:hover,
  .message__something-send-btn__MKyrgI:active,
  .message__something-send-btn__MKyrgI:active:focus {
    background-color: #ff9800 !important;
    color: #ffffff !important;
    border-color: #ff9800 !important;
    outline: none !important;
  }

.message__something-send-btn__MKyrgI:active:disabled,
  .message__something-send-btn__MKyrgI:disabled {
    cursor: default !important;
    border: 1px solid #ff9800;
    background-color: #ffffff !important;
    color: #ff9800 !important;
  }

.message__something-send-btn__MKyrgI:active,
.message__something-send-btn__MKyrgI:active:focus,
.message__something-send-btn__MKyrgI:active:hover {
  outline: none !important;
  box-shadow: none !important;
}

/*.something-send-btn:hover {*/
/*  background-color: #FFFFFF !important;*/
/*  border-color: #FFFFFF !important;*/
/*  color: #ff9800 !important;*/
/*}*/

.message__image-btn__kaWPzD {
  height: 18px;
  padding: 4px 15px !important;
  color: #1e88e5;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
}

.message__loadingContainer__nq35nr {
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  justify-content: center;
  align-items: center;
  height: 102px;
}

.message__loadingContainerCustomer__ljbOEu {
  background-color: rgb(255, 243, 224);
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  justify-content: center;
  align-items: center;
  height: 102px;
}

.message__error__QNbfYD {
  display: flex;
  color: #ef5350;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

.message__orderInfo__IfViEa {
  padding: 16px;
  border-width: 0 1px 1px;
  background: #ffe0b2;
}

.message__translationType__EeIe_p {
  padding-bottom: 8px;
  color: #212121;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
}

.message__translationTypeTitle__zwDmY1 {
  font-weight: 400;
  margin-bottom: 8px;
}

.message__charsLimit__YC7OxI {
  color: #212121;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 300;
}

.message__charsLimitTitle__JmiDJr {
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}

.message__optionsContainer__WQAHX5 {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  z-index: 10;
}

.message__svg__Iktv84 {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.message__arrowContainer__xi3QpF {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  z-index: 10;
  height: 0;
  align-items: flex-end;
}

.message__arrowDown__x8mlnG {
  position: relative;
  height: 31px;
  width: 31px;
  min-width: 31px;
  min-height: 31px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.message__arrow-label__jdgCVW {
  position: absolute;
  width: auto;
  min-width: 13px;
  height: 13px;
  top: -1px;
  left: 19px;
  border-radius: 8px;
  padding: 2px 4px;
  background: #ff9800;

  font-family: "Roboto";
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #ffffff;
}

.message__imageList__C8licX {
  display: grid;
  grid-template-columns: repeat(2, 116px);
  column-gap: 16px;
  row-gap: 16px;
  margin-top: 16px;
  padding: 0 16px 2px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `message__content__bWiUuh`,
	"content-borders": `message__content-borders__bAtf2J`,
	"message-container": `message__message-container__Kwevdv`,
	"content-translator": `message__content-translator__GMt9xR`,
	"message-block": `message__message-block__h_pKKK`,
	"input-block": `message__input-block__KSchDW`,
	"question": `message__question__Tq6eea`,
	"thumb-vertical": `message__thumb-vertical__JLYjlo`,
	"input-container": `message__input-container__ZGjvIe`,
	"content-empty": `message__content-empty__QR70eb`,
	"button-panel": `message__button-panel__vXhc24`,
	"button-container": `message__button-container__mAj45U`,
	"control-buttons-container": `message__control-buttons-container__rmxLQH`,
	"clear": `message__clear__rqDL9_`,
	"something-send-btn": `message__something-send-btn__MKyrgI`,
	"image-btn": `message__image-btn__kaWPzD`,
	"loadingContainer": `message__loadingContainer__nq35nr`,
	"loadingContainerCustomer": `message__loadingContainerCustomer__ljbOEu`,
	"error": `message__error__QNbfYD`,
	"orderInfo": `message__orderInfo__IfViEa`,
	"translationType": `message__translationType__EeIe_p`,
	"translationTypeTitle": `message__translationTypeTitle__zwDmY1`,
	"charsLimit": `message__charsLimit__YC7OxI`,
	"charsLimitTitle": `message__charsLimitTitle__JmiDJr`,
	"optionsContainer": `message__optionsContainer__WQAHX5`,
	"svg": `message__svg__Iktv84`,
	"arrowContainer": `message__arrowContainer__xi3QpF`,
	"arrowDown": `message__arrowDown__x8mlnG`,
	"arrow-label": `message__arrow-label__jdgCVW`,
	"imageList": `message__imageList__C8licX`
};
export default ___CSS_LOADER_EXPORT___;
