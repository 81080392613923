import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import message from "../ContextOrderMessages/css/message.css";
import { I18n } from "react-redux-i18n";
import OptionButton from "../OptionButton/OptionButton";
import Question from "../Title/components/Question";

export const categoryCodeList = {
  0: "GENERAL",
  1: "APPS",
  2: "CRYPTO",
  3: "CASINO",
  4: "LEGAL",
  5: "E_COMMERCE",
  6: "FINANCE",
  7: "GAMES",
  8: "MEDIA",
  9: "MEDICAL",
  10: "SCIENCE",
  11: "IT",
  12: "TRAVEL",
};

export const translationTypeList = {
  0: "TRANSLATOR_GUESS",
  1: "FORMAL",
  2: "INFORMAL",
};

export const AdvancedOptionsResult = ({
  translationCategory,
  translationType,
  charsLimit,
  userRole,
  editAdvancedOptionsHandler,
  status,
}) => {
  const translationCategoryCode = categoryCodeList[translationCategory];
  const translationTypeTone =
    typeof translationType === "number" ? translationTypeList[translationType] : translationType;
  const shouldShowAdvancedOptions =
    (userRole === "ADMIN" && status !== "done" && status !== undefined) ||
    (userRole === "CUSTOMER" && status === "queue");
  const questionText = {
    ADMIN: I18n.t("ORDER_MESSAGES.CHARS_LIMIT_QUESTION_HINT"),
    CUSTOMER: shouldShowAdvancedOptions
      ? I18n.t("CUSTOMER_APP.ORDER_PAGE.COMMENTS_QUESTION_TEXT.CHARS_LIMIT_QUESTION_HINT_WITH_DOTS")
      : I18n.t("CUSTOMER_APP.ORDER_PAGE.COMMENTS_QUESTION_TEXT.CHARS_LIMIT_QUESTION_HINT"),
    TRANSLATOR: I18n.t("TRANSLATOR_APP.DESKTOP_PAGE.CHARS_LIMIT_QUESTION_HINT"),
  };
  return (
    <Fragment>
      {shouldShowAdvancedOptions && (
        <OptionButton
          divStyleName={"message.optionsContainer"}
          svgStyleName={"message.svg"}
          onClick={editAdvancedOptionsHandler}
        />
      )}
      <div styleName={"message.orderInfo"}>
        <div styleName={"message.translationType"}>
          <span styleName={"message.translationTypeTitle"}>
            {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY")}:&nbsp;
          </span>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU." + translationCategoryCode)}
        </div>
        <div styleName={"message.translationType"}>
          <span styleName={"message.translationTypeTitle"}>
            {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.LABEL")}:&nbsp;
          </span>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.MENU." + translationTypeTone)}
        </div>
        <div styleName={"message.charsLimit"}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <div styleName={"message.charsLimitTitle"}>{I18n.t("ORDER_MESSAGES.CHARS_LIMIT")}:&nbsp;</div>
            <div>{charsLimit > 0 ? charsLimit : I18n.t("ORDER_MESSAGES.NO_LIMIT")}</div>
            <span style={{ marginLeft: 6 }}>
              <Question question={questionText[userRole]} iconColor={"iconBlack"} />
            </span>
          </span>
        </div>
      </div>
    </Fragment>
  );
};
