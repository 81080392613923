var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Popup from "../../../elements/Popup";
import styles from "./css/style.css";
import { I18n } from "react-redux-i18n";
import HtmlTranslate from "../../../HtmlTranslate";
import FormInput from "../../FormInput";
import NitroButton from "../../GreenButton";
import { checkIsEmailValid } from "../../../../helpers/common";
import { showSuccessNotification, showWarningErrorNotification } from "../../../../actions/NotificationActions";
import { useDispatch } from "react-redux";
var stylePopup = {
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 120,
    paddingBottom: 150,
};
var styleInputContainer = { margin: "16px 0 14px" };
var styleInputLabel = { marginBottom: 5 };
export var SUBMIT_BUTTON_STYLES = { minWidth: 78, height: 32, fontSize: 14, fontWeight: 300 };
var PopupFinishLater = function (_a) {
    var click = _a.click, isOpened = _a.isOpened, setIsOpened = _a.setIsOpened;
    var dispatch = useDispatch();
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    var _c = useState(""), error = _c[0], setError = _c[1];
    var onClose = function () {
        setIsOpened(false);
    };
    var handleInputChange = function (e) {
        setEmail(e.target.value);
    };
    var validate = function () {
        if (!email) {
            setError("INPUT_REQUIRED");
            return false;
        }
        var isValid = checkIsEmailValid(email);
        if (!isValid)
            setError("INPUT_INCORRECT_EMAIL");
        return isValid;
    };
    var handleSuccess = function () {
        onClose();
        dispatch(showSuccessNotification(I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.SUCCESS")));
    };
    var handleSubmitClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isValid, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isValid = validate();
                    if (!isValid)
                        return [2 /*return*/];
                    return [4 /*yield*/, click(email)];
                case 1:
                    data = _a.sent();
                    if (!data) {
                        dispatch(showWarningErrorNotification(I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.FAIL")));
                    }
                    if (data)
                        handleSuccess();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Popup, { stylePopup: stylePopup, containerStyle: { overflowY: "initial" }, className: styles.container, isOpen: isOpened, onClose: onClose, isHeader: _jsx("div", { children: _jsx("h1", { className: styles.title, children: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.TITLE") }) }), children: [_jsxs("div", { children: [_jsx("p", { className: styles.description, children: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.DESCRIPTION") }), _jsx(FormInput, { styleContainer: styleInputContainer, labelStyle: styleInputLabel, label: "Email", onChange: handleInputChange, value: email, status: error }), _jsx(HtmlTranslate, { classNames: styles.agreement, value: "CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.POLICY", termsLink: I18n.t("SIGNUP_PAGE.SIGNUP_FORM.TERMS_LINK"), privacyLink: I18n.t("SIGNUP_PAGE.SIGNUP_FORM.PRIVACY_LINK") })] }), _jsxs("div", { className: styles.buttonsWrapper, children: [_jsx(NitroButton, { clickHandler: handleSubmitClick, style: SUBMIT_BUTTON_STYLES, title: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.SAVE") }), _jsx("button", { className: styles.clearButton, onClick: onClose, children: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FINISH_LATER_POPUP.CANCEL") })] })] }));
};
export default PopupFinishLater;
