// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__adaptive-width__BmdZfW {
  padding: 96px 4rem 0;
  margin-top: 5.4rem;
}

.container__new-order__bMO2kI {
}

.container__unauth-new-order__paDWeA {
  padding: 112px 0 0;
}

.container__loading-container__uiIzbO {
  margin-top: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__loading__GaHZRh {
  align-self: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media (max-width: 658px) {
  .container__adaptive-width__BmdZfW {
    padding: 96px 0 0;
    margin-top: 0;
  }

  .container__new-order__bMO2kI {
    padding: 96px 0 0;
  }

  .container__litle-exit-icon__YRiLxt {
    align-self: flex-start;
    margin-top: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 380px) {
  .container__adaptive-width__BmdZfW {
    padding: 96px 0 0;
    margin-top: 0;
  }

  .container__new-order__bMO2kI {
    padding: 96px 0 0;
    min-width: 320px;
  }

  .container__litle-exit-icon__YRiLxt {
    align-self: flex-start;
    margin-top: 16px;
    margin-right: 16px;
  }
}

.container__full-width__FvYYUz {
  background: #eee;
  margin: 5.4rem auto 0;
  padding: 106px 16px 0;
  width: auto;
  white-space: nowrap;
}

.container__star-width__FfLVo_ {
  background: #eee;
  margin: 5.4rem auto 0;
  padding: 76px 16px 0;
  width: auto;
  white-space: nowrap;
}

.container__open-chat-btn__Bxv4TQ {
  text-align: center !important;
  font-size: 14px !important;
  border-radius: 3px !important;
  border: 1px solid #1e88e5 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0) !important;
  background-color: #ffffff !important;
  color: #1e88e5 !important;
  line-height: 16px !important;
  padding: 4px 10px !important;
}

.container__open-chat-btn__Bxv4TQ:disabled {
  cursor: default !important;
}

.container__open-chat-btn__Bxv4TQ:hover,
.container__open-chat-btn__Bxv4TQ:active,
.container__open-chat-btn__Bxv4TQ:active:hover,
.container__open-chat-btn__Bxv4TQ:active:focus {
  color: #ffffff !important;
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
  outline: none !important;
  cursor: pointer !important;
}

.container__open-chat-btn__Bxv4TQ:active,
.container__open-chat-btn__Bxv4TQ:active:focus,
.container__open-chat-btn__Bxv4TQ:active:hover {
  box-shadow: none !important;
}

.container__clear__QuUDY0 {
  cursor: pointer;
  height: 24px;
  width: 65px;
  color: #9e9e9e;
  font-size: 14px;
  line-height: 24px;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  display: inline-block;
}

.container__translatedLink__rkueii a {
  cursor: pointer;
}

.container__question__pSyS7U {
  margin-top: 27px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.container__billToContainer__JGXhxD {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 5px;
  min-height: 121px;
  width: 446px;
  border: 1px solid #cecece;
  background-color: #f5f5f5;
  padding: 8px;
  white-space: pre-wrap;
}

.container__billToContainer__JGXhxD.container__error__SS__i_ {
  border-color: #ef5350;
}

.container__billToError__DbZGhk {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #ef5350;
  margin-top: 8px;
  display: block;
}

.container__notifications-settings__iOTiMB {
  white-space: normal;
  font-size: 14px;
  line-height: 19px;
  color: #424242;
  margin: -9px 0 10px;
}

.container__non-user__YSRT9X {
  display: flex;
  align-items: flex-end;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adaptive-width": `container__adaptive-width__BmdZfW`,
	"new-order": `container__new-order__bMO2kI container__adaptive-width__BmdZfW`,
	"unauth-new-order": `container__unauth-new-order__paDWeA`,
	"loading-container": `container__loading-container__uiIzbO`,
	"loading": `container__loading__GaHZRh`,
	"litle-exit-icon": `container__litle-exit-icon__YRiLxt`,
	"full-width": `container__full-width__FvYYUz`,
	"star-width": `container__star-width__FfLVo_`,
	"open-chat-btn": `container__open-chat-btn__Bxv4TQ`,
	"clear": `container__clear__QuUDY0`,
	"translatedLink": `container__translatedLink__rkueii`,
	"question": `container__question__pSyS7U`,
	"billToContainer": `container__billToContainer__JGXhxD`,
	"error": `container__error__SS__i_`,
	"billToError": `container__billToError__DbZGhk`,
	"notifications-settings": `container__notifications-settings__iOTiMB`,
	"non-user": `container__non-user__YSRT9X`
};
export default ___CSS_LOADER_EXPORT___;
