// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.style__container__ZMAo_t {
    position: relative;
    margin: 0;
    /*border-radius: 6px;*/
    background-color: #FFF;
    padding: 0px 16px 24px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    top: initial !important;
    /*min-width: px;*/
    width: 544px;
    max-width: 544px;
}

.style__title__daJKiO {

    position: relative;
    margin: -4px 0 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
}

.style__description__e_HMUV {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
}

.style__description__e_HMUV:first-child {
    padding-top: 9px;
}

.style__agreement__EFzVdp {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
}

.style__buttonsWrapper__D9P3hh {
    padding-top: 24px;
    font-size: 14px !important;
    display: flex;
    align-items: center;
}

.style__clearButton__jot61k {
    cursor: pointer;
    height: 24px;
    min-width: 65px;
    color: #9E9E9E;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    display: inline-block;
    margin-left: 5px;
    border: none;
    background-color: transparent;
}

.style__clearButton__jot61k:active {
    outline: none !important;
}

.style__clearButton__jot61k:focus {
    box-shadow: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style__container__ZMAo_t`,
	"title": `style__title__daJKiO`,
	"description": `style__description__e_HMUV`,
	"agreement": `style__agreement__EFzVdp`,
	"buttonsWrapper": `style__buttonsWrapper__D9P3hh`,
	"clearButton": `style__clearButton__jot61k`
};
export default ___CSS_LOADER_EXPORT___;
