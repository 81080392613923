import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";
// eslint-disable-next-line no-unused-vars
import priceTable from "./css/priceTable.css";
import Question from "../refactored/Title/components";
import { toFixed } from "../../helpers/newOrder/getOrderPrice";
import { HandySvg } from "handy-svg";
import spinner from "../../images/svg/spinner-blue.svg";
import HtmlTranslate from "../HtmlTranslate";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

const getTableClassNameByOrderType = locale => {
  return locale === "ru" ? "priceTable.tableContainerFileRus" : "priceTable.tableContainerFile";
};

export default function PriceTableGeneral({
  files,
  filesTotalPrice,
  discount,
  sourceLanguage,
  formatCurrency,
  sourceText,
  discountsTMList,
  loadingDiscountTM,
  orderType,
  selectedTargetLanguage,
  getTMDiscountFunction,
  handleApplyTmPmDiscount,
  showBtnCalculate,
  handleShowBtnCalculate,
  page,
  handleBtnCalculateWithoutAuth,
  selectedService,
}) {
  const i18n = useSelector(state => state.i18n);

  const data = files?.length ? files : sourceText ? [sourceText] : [];
  const targetPrices = filesTotalPrice?.targetPrices?.length ? filesTotalPrice?.targetPrices : [];

  const memoizedClassNames = useMemo(() => {
    return {
      tableContainer: getTableClassNameByOrderType(i18n.locale),
    };
  }, [i18n.locale]);

  const calculateDiscount = () => {
    handleShowBtnCalculate();
    handleApplyTmPmDiscount(true);
    getTMDiscountFunction({
      orderType,
      sourceLanguage: sourceLanguage,
      selectedTargetLanguage: selectedTargetLanguage,
    });
  };

  return (
    <div styleName={memoizedClassNames.tableContainer}>
      <div styleName={"priceTable.tableRow priceTable.source"}>
        <div
          styleName={`priceTable.tableHeader ${"priceTable.tableHeaderFile"}`}
          style={{
            minWidth: i18n.locale === "ru" ? "105px" : "inherit",
          }}
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.SOURCE")}
        </div>
        {data.length > 0 && targetPrices?.length > 0 && (
          <div styleName={"priceTable.tableText"}>{I18n.t("LANGUAGES." + sourceLanguage.value)}</div>
        )}
      </div>

      <div styleName={"priceTable.tableRow priceTable.volume"}>
        <div styleName={`priceTable.tableHeader ${"priceTable.tableHeaderFile priceTable.tableHeaderFileRight"}`}>
          <p styleName={"priceTable.volumeTitle"}>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.VOLUME")}</p>
        </div>
        {data.length > 0 && targetPrices?.length > 0 && (
          <div styleName={"priceTable.tableText priceTable.tableVolumeText"}>{data[0].volume}</div>
        )}
      </div>

      <div styleName={"priceTable.tableRow priceTable.volumeFake"}>
        <div styleName={"priceTable.tableHeader priceTable.tableHeaderFile"}>
          <p styleName={"priceTable.volumeTitle"}>{" volume "}</p>
        </div>
      </div>

      <div styleName={"priceTable.tableRow priceTable.target"}>
        <div
          style={{
            height: i18n.locale === "ru" && orderType === "file" && "72px",
          }}
          styleName={"priceTable.tableHeader " + "priceTable.tableHeaderFile"}
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.TARGET")}
        </div>
        {data.length > 0 &&
          targetPrices.map(target => {
            return <div styleName={"priceTable.tableText"}>{target.lang.label}</div>;
          })}
      </div>

      <div styleName={"priceTable.tableRow priceTable.pm"}>
        <div
          styleName={
            "priceTable.tableHeader priceTable.tableHeaderQuestion priceTable.tableHeaderFile priceTable.pmTitle"
          }
          style={{
            height: i18n.locale === "ru" && orderType === "file" && "72px",
          }}
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.PM_CHARS")}
          <div styleName={`priceTable.pmQuestion`}>
            <Question question={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.PM_QUESTION")} />
          </div>
        </div>
        {data.length > 0 &&
          targetPrices.map((target, index) => {
            return (
              <div styleName={"priceTable.tableText"}>
                <p styleName={"priceTable.questionCell"}>
                  {discountsTMList && discountsTMList[index] && !showBtnCalculate && !loadingDiscountTM
                    ? discountsTMList[index].pmVolume
                    : "-"}
                </p>
              </div>
            );
          })}
      </div>

      <div styleName={"priceTable.tableRow priceTable.tm"}>
        <div
          style={{
            height: i18n.locale === "ru" && orderType === "file" && "72px",
            paddingTop: i18n.locale === "ru" && orderType === "file" && "22px",
          }}
          styleName={
            "priceTable.tableHeader priceTable.tableHeaderQuestion priceTable.tableHeaderFile priceTable.pmTitle"
          }
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.TM_CHARS")}
          <div styleName={`priceTable.pmQuestion`}>
            <Question question={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.TM_QUESTION")} />
          </div>
        </div>
        {data.length > 0 &&
          targetPrices.map((target, index) => {
            return (
              <div styleName={"priceTable.tableText"}>
                <p styleName={"priceTable.questionCell"}>
                  {discountsTMList && discountsTMList[index] && !showBtnCalculate && !loadingDiscountTM
                    ? discountsTMList[index].volume
                    : "-"}
                </p>
              </div>
            );
          })}
      </div>

      {orderType === "googleFile" && (
        <div styleName={"priceTable.tableRow priceTable.tableTragetLangRowMobile priceTable.emptyCell"}>
          <div
            styleName={"priceTable.tableHeader " + (orderType === "googleFile" ? "priceTable.tableHeaderFile" : "")}
            // style={{minHeight: 30, width: 118, height: 51}}
          >
            {" "}
          </div>
        </div>
      )}

      <div styleName={"priceTable.tableRow priceTable.tableTragetLangRowMobile priceTable.target2"}>
        <div styleName={"priceTable.tableHeader " + "priceTable.tableHeaderFile"}>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.TARGET")}
        </div>
        {data.length > 0 &&
          targetPrices.map(target => {
            return <div styleName={"priceTable.tableText"}>{target.lang.label}</div>;
          })}
      </div>

      <div styleName={"priceTable.tableRow priceTable.discount"}>
        <div
          styleName={
            "priceTable.tableHeader priceTable.tableTmHeader " +
            "priceTable.tableHeaderFile" +
            (i18n.locale === "ru" && orderType === "file" ? " priceTable.tableHeaderFileRus" : "")
          }
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.DISCOUNT")}
          <div styleName={`priceTable.pmQuestion`}>
            <Question
              question={
                orderType === "file"
                  ? I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT_TM.DESCRIPTION")
                  : I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT_TM.DESCRIPTION_GOOGLE")
              }
            />
          </div>
        </div>
        {data.length > 0 &&
          targetPrices.map((target, index) => {
            return (
              <div styleName={"priceTable.tableText priceTable.tableTMText priceTable.questionCell"}>
                {showBtnCalculate && index === 0 ? (
                  <p>
                    <Button
                      id="discountBtn"
                      onClick={page === "withoutAuth" ? handleBtnCalculateWithoutAuth : calculateDiscount}
                      styleName={"priceTable.discountBtn"}
                    >
                      {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.DISCOUNT_BTN")}
                    </Button>
                  </p>
                ) : (
                  <div>
                    {discountsTMList && discountsTMList[index] && !loadingDiscountTM ? (
                      toFixed(discountsTMList[index].percent * 100)
                    ) : loadingDiscountTM && !showBtnCalculate ? (
                      <div styleName={"priceTable.spinnerContainer"}>
                        <HandySvg className="spinner-file-loading" width="16" height="16" src={spinner} />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <div styleName={"priceTable.tableRow priceTable.price"}>
        <div
          styleName={
            "priceTable.tableHeader priceTable.tablePriceHeader " +
            "priceTable.tableHeaderFile" +
            (i18n.locale === "ru" && orderType === "file" ? " priceTable.tableHeaderFileRus" : "")
          }
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.PRICE")}
        </div>
        {data.length > 0 &&
          targetPrices.map((target, index) => {
            const usd = discount
              ? target?.priceInfoUSD?.customerPriceWithoutDiscountAmount
              : target?.priceInfoUSD?.customerPrice;
            const jpy = discount
              ? target?.priceInfoJPY?.customerPriceWithoutDiscountAmount
              : target?.priceInfoJPY?.customerPrice;
            const shouldShowAttentionMark = !showBtnCalculate && discountsTMList?.[index] && data.length;

            const shouldShowAttentionMarkForDefaultTranslation =
              shouldShowAttentionMark && discountsTMList[index].pmVolume === data[0].volume;

            const shouldShowAttentionMarkForAI =
              shouldShowAttentionMark &&
              selectedService === "AI" &&
              (discountsTMList[index].pmVolume === data[0].volume ||
                discountsTMList[index].pmVolume + discountsTMList[index].volume === data[0].volume ||
                discountsTMList[index].volume === data[0].volume);

            let attentionText = "";
            if (shouldShowAttentionMarkForDefaultTranslation)
              attentionText = "CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.PM_ATTENTION";
            if (shouldShowAttentionMarkForAI)
              attentionText = "CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.AI_TM_PM_ATTENTION";

            return (
              <div styleName={"priceTable.tableText priceTable.priceText"} style={{ textAlign: "right" }}>
                {attentionText && (
                  <div style={{ marginRight: "5px", marginTop: "2px" }}>
                    <Question
                      question={
                        <HtmlTranslate
                          value={attentionText}
                          source={I18n.t("LANGUAGES." + sourceLanguage.value)}
                          target={target.lang.label}
                        />
                      }
                      iconColor="iconRed"
                      iconType="info"
                    />
                  </div>
                )}
                {formatCurrency({ usd, jpy })}
              </div>
            );
          })}
      </div>

      {data.length > 0 && filesTotalPrice ? (
        <div
          styleName={"priceTable.totalPrice"}
          style={{
            justifySelf: "end",
            alignContent: "end",
            textAlign: "right",
            textDecoration: discount ? "line-through" : "none",
          }}
        >
          {filesTotalPrice &&
            formatCurrency(
              discount
                ? {
                    usd: filesTotalPrice?.customerPriceWithoutDiscountAmountTotal?.usd,
                    jpy: filesTotalPrice?.customerPriceWithoutDiscountAmountTotal?.jpy,
                  }
                : { usd: filesTotalPrice?.priceWithDiscount?.usd, jpy: filesTotalPrice?.priceWithDiscount?.jpy }
            )}
        </div>
      ) : (
        <div styleName={"priceTable.tableEmptyText"}>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.EMPTY_TABLE")}
        </div>
      )}
    </div>
  );
}
