import React, { Component, Fragment } from "react";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import ScrollNavigator from "../../components/navigation/ScrollNavigator/ScrollNavigator";
import BlockContent from "../../components/refactored/NewOrderForm/components/BlockContent/BlockContent";
import TextareaAutoresize from "../../components/refactored/NewOrderForm/components/Textarea/TextareaAutoresize";
import SourceTextareaAutoresize from "../../components/refactored/NewOrderForm/components/Textarea/SourceTextareaAutoresize";
import SettingsToolbar from "../../components/toolbars/SettingsToolbar";
import UnderHeader from "../../components/refactored/UnderHeader/UnderHeader";
import NitroButton from "../../components/refactored/GreenButton/NitroButton";
import { createLocalFn } from "../../helpers/getLocalizedString";
import { translatedTextWithLink } from "../../helpers/translatedTextWithLink";
// eslint-disable-next-line no-unused-vars
import baseContainer from "../../css/container.css";
// eslint-disable-next-line no-unused-vars
import form from "../../components/refactored/NewOrderForm/css/newOrder.css";
// eslint-disable-next-line no-unused-vars
import link from "../../components/refactored/LinkWrapper/css/link.css";
// eslint-disable-next-line no-unused-vars
import button from "../../components/refactored/GreenButton/css/green-button.css";
// eslint-disable-next-line no-unused-vars
import bar from "../../components/refactored/ToolBar/css/toolbar.css";
// eslint-disable-next-line no-unused-vars
import order from "./../../components/refactored/OrderEditBlock/customer/css/languages.css";
// eslint-disable-next-line no-unused-vars
import row from "./../../components/Table/components/TableRow/css/table-row.css";
// eslint-disable-next-line no-unused-vars
import newOrder from "./css/new-order.css";
import AddImageButton from "../../components/refactored/AddImageButton";
import UploadingImageView from "../../components/refactored/UploadingImageView";
import { cursorLocalWait } from "../../settings/cursorWait";
import { checkImage } from "../../helpers/checkImage";
import { limitImageSize, limitStringsSize } from "../../constants/Orders";
import DropdownMenu from "../../components/refactored/NewOrderForm/components/DropdownMenu";
import TableLanguagesBlock from "../../components/refactored/NewOrderForm/components/TableLanguagesBlock";
import LinkWrapper from "../../components/refactored/LinkWrapper";
import { isDownArrow, isEnter, isSpace, isTab, isUpArrow } from "../../constants/KeyboardKeys";
import Toolbar from "../../components/refactored/ToolBar/ToolBar";
import { formatCurrency, formatNumAsCurrency } from "../../helpers/formatCurrency";
import { JPY, USD } from "../../constants/Currency";
import { DESC } from "../../constants/Sort";
import { mapRatesToLanguages } from "../../helpers/mapRatesToLanguages";
import PopupDialog from "../../components/refactored/Popups/PopupDialog";
import { getCouponFromUrl, getDiscountFromUrl, getUuidFromUrl, getIsAIFromUrl } from "../../helpers/getCouponFromUrl";
import CustomerBadge from "../../components/refactored/CustomerBadge";
import settingsBalance from "../../components/settings/settingsBalance";
import { showNewMessageChat } from "../../helpers/intercomChat";
import { getCookie, setCookie } from "../../helpers/common";
import { limit } from "stringz";
import validateNewOrder from "../../helpers/newOrder/validateNewOrder";
import * as ReactDOM from "react-dom";
import { getWarningMessage } from "../../helpers/newOrder/defineCommentariesWarning";
import DefaultSelect from "../../components/refactored/Select";
import { addFundsFormSelectOption, addFundsFormSelectStyle } from "../../components/refactored/Select/addFundsForm";
import FormInput from "../../components/refactored/FormInput";
import { roundToTwo } from "../../helpers/formatRate";
import { localeFormSelectStyle } from "../../components/refactored/Select/settings";
import Tutorial from "../../components/refactored/Tutorial";
import moment from "moment";
import { Discount } from "../../components/refactored/Discount/Discount";
import HtmlTranslate from "../../components/HtmlTranslate";
import url from "url";
import { Button } from "react-bootstrap";
import nodeUrl from "url";
import PriceTableWithoutAuth from "../../components/PriceTableGeneral/PriceTableGeneral";
import TableBlock from "../../components/refactored/TableBlock";
import { filesColumns } from "../../settings/customer/FilesTable";
import { AdvancedOptions } from "../../components/refactored/AdvancedOptions/AdvancedOptions";
import DeliveryTimeTable from "../../components/DeliveryTimeTable";
import googleDriveIcon from "../../images/svg/google-drive.svg";
import { HandySvg } from "handy-svg";
import computerIcon from "../../images/svg/Computer.svg";
import spinner from "../../images/svg/spinner-blue.svg";
import {
  advancedSettingsList,
  categorySettingsList,
} from "../../components/refactored/AdvancedOptions/AdvancedOptionsConstants";
import CheckBoxPopup from "../customer/CheckBoxPopup/CheckBoxPopup";
import sheetsIcon from "../../images/svg/sheets-icon.svg";
import docsIcon from "../../images/svg/docs-icon.svg";
import { OrderTranslationCategory } from "@alconost/nitro-services-api/src/proto/order_service_pb";
import Question from "../../components/refactored/Title/components";
import { trackEventChat } from "../../helpers/intercomChat";
import { unicodeToString, formatString } from "../../helpers/formatString";
import triangleIcon from "../../images/svg/triangle-down.svg";
import ImageErrorMessage from "../../components/refactored/ImageErrorMessage";
import PopupPreview from "../../components/refactored/Popups/PopupPreview";
import IcomoonReact from "icomoon-react";
import iconSet from "../../images/selection.json";
import IconView from "../../components/refactored/IconView";
import { withRouter } from "../CustomerApp";
import { withWebsocket } from "../../components/refactored/Websocket";
import { PopoverTest } from "../../components/Popover/PopoverTest";
import { Outlet } from "react-router";
import { ORDER_FORMATS } from "../../constants/Order";
import { UploadFilePopup } from "../../components/UploadFilePopup/UploadFilePopup";
import PopupCSVSettings from "../../components/refactored/Popups/PopupCSVSettings/PopupCSVSettings";
import PopupCSVSelector from "../../components/refactored/Popups/PopupCSVSelector/PopupCSVSelector";
import { isJsonString } from "../../helpers/isJsonString";
import { getFormattedCustomerPricesAsNumber, getFormattedPriceCurrenciesAsNumber } from "./helpers";
import classnames from "classnames";
import { saveDraftUnregisterRequest, getDraftUnregisterRequest } from "../../services/DraftServices/requests";
import PopupFinishLater from "../../components/refactored/Popups/PopupFinishLater";
import { translationTypeList } from "../../components/refactored/AdvancedOptionsResult/AdvancedOptionsResult";
import {
  ORDER_TYPES_BY_SELECTED_SERVICE,
  SERVICE_NUMBER_BY_VALUE,
  SERVICE_VALUE_BY_NUMBER,
  translationServiceOptionsAuth,
  AIOption,
} from "../../helpers/translatioServiceOptions";
import { CouponPolicy } from "@alconost/nitro-services-api/src/proto/base_pb";
const ENGLISH_EN_EN_LANG_VALUE = "en";
const PORTUGES_LANG_VALUE = "pt";
const UNDEFINED_LANG_VALUE = "und";
const PORTUGES_DEFAULT_LANG_VALUE = "pt-PT";

let imageLoading = "";
let cancelUploadArray = [];
const OFFSET_CUSTOMER_RATES = 0;
const LIMIT_CUSTOMER_RATES = 5000;
const SORT_FIELD = "RATE_ID";
const AUTO_DETECT_LANGUAGE_DELAY = 1000;
const newOrderPageLocal = createLocalFn("CUSTOMER_APP.NEW_ORDER_PAGE");
const SPREADSHEETS_SERVICE_NAME = "spreadsheets";
const GOOGLE_ERROR_STATUSES = {
  "Unsupported file format": {
    googleSheetsUrlStatus: "unsupportedSheetsFormat",
    googleDriveUrlStatus: "unsupportedFileFormat",
  },
  "The file is not accessible": {
    googleDriveUrlStatus: "noAccessLink",
    googleSheetsUrlStatus: "noAccessLink",
  },
  "file size": {
    googleDriveUrlStatus: "nullFileSize",
    googleSheetsUrlStatus: "nullFileSize",
  },
  "The file has no text to translate": {
    googleDriveUrlStatus: "noTextToTranslate",
    googleSheetsUrlStatus: "noTextToTranslate",
  },
};

class NewOrderGeneralWithoutAuth extends Component {
  constructor(props) {
    super(props);

    const hasAI = getIsAIFromUrl() || window?.localStorage.getItem("ai");
    const serviceOptions = hasAI ? [...translationServiceOptionsAuth, AIOption] : translationServiceOptionsAuth;

    const emptyPriceUSD = formatNumAsCurrency({
      value: 0,
      currency: USD,
      showIsZero: true,
    });
    this.state = {
      placeholderMenu: {
        visible: false,
        label: newOrderPageLocal("SELECT"),
      },
      sourceText: "",
      validateErrors: {},
      scrollToElementId: "",
      onFocusSource: false,
      focusDetectedBtn: false,
      languageBlockWidth: 644,
      isLoadingMe: false,
      dialogOpen: false,
      dialogSignInOpen: false,
      showBigOrderTextWithLinkElement: true,
      isShowAddFundsBlock: true,
      paymentMethodValue: {
        value: "STRIPE",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.STRIPE"),
      },
      amount: "",
      couponDescriptionDisplay: false,
      couponLabel: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.AMOUNT"),
      buttonLabel: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADD_FUNDS"),
      equivalentDisplay: false,
      amountCurrency: "RUB",
      currencyAvailableWithSymbol: this.getBalance(),
      isShowAdvancedSettings: false,
      //if you need enable tutorial -> put !localStorage.getItem("completed_tutorial") instead of false below
      isShowPopover: false
        ? {
            id: "source",
            text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.SOURCE"),
            nextBlock: "context",
            scrollId: "context",
            handlerDone: this.handlerDonePopover,
            handlerClose: this.handlerClosePopover,
          }
        : {},
      popupMessages: {
        sourceLangErrors: false,
      },
      textClose: I18n.t("CUSTOMER_APP.ORDERS_PAGE.QUEUE_SECTION.BUTTON_CLOSE"),
      uploadGoogleDrivePopupShow: false,
      uploadGoogleSheetsPopupShow: false,
      uploadComputerPopupShow: false,
      isUploadFilePopoverOpen: false,
      files: [],
      sidebarInfoPriceRef: {},
      sidebarInfoCounterRef: {},
      filePreviewPopup: false,
      typeGoogleFile: "",
      uploadFilePopup: false,
      uploadCSVSettingsPopup: false,
      uploadCSVSelectorPopup: false,
      isUploadCSVLoading: false,
      uploadFileElements: [],
      uploadFileParserInfo: {},
      uploadFileDefaultParserInfoError: false,
      showBtnCalculate: true,
      loadingGoogleSource: false,
      loadingGoogleSheetTable: false,
      isPopupFinishLaterOpened: false,
      wasAdvancedSettingsSelectorsChanged: false,
      selectedService: serviceOptions[0].value,
      hasAI,
      serviceBlockOptions: serviceOptions,
      sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(),
      priceSidebarInfo: emptyPriceUSD,
      langsWithProofread: {},
      isLongSpreadsheetLoading: false,
      abortController: {},
    };

    this.longSpreadsheetLoadingTimer = null;
  }

  handlerDonePopover = id => {
    const popoverList = {
      source: {
        id: "source",
        text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.SOURCE"),
        nextBlock: "selectSourceLang",
      },
      selectSourceLang: {
        id: "selectSourceLang",
        text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.SOURCE_LANGUAGE"),
        nextBlock: "target",
      },
      target: {
        id: "target",
        text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.TARGET_LANGUAGE"),
        nextBlock: "context",
      },
      context: {
        id: "context",
        text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.CONTEXT"),
        nextBlock: "addFunds",
      },
      addFunds: {
        id: "addFunds",
        text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.BALANCE"),
        nextBlock: "createOrder",
      },
      createOrder: {
        id: "createOrder",
        text: I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.TEXT.SEND_TO_TRANSLATION_BTN"),
        nextBlock: "",
        onlyLeft: true,
      },
    };
    const nextBlock = popoverList[id].nextBlock;
    if (popoverList[id].id === "createOrder") {
      localStorage.setItem("completed_tutorial", "true");
    }

    if (nextBlock) {
      this.setState({
        isShowAddFundsBlock: nextBlock === "addFunds" || nextBlock === "createOrder",
        isShowPopover: {
          ...this.state.isShowPopover,
          ...popoverList[nextBlock],
        },
      });
      nextBlock === "createOrder" &&
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
    } else {
      this.handlerClosePopover();
    }
  };
  handleOpenPreviewPopup = () => {
    this.setState({
      filePreviewPopup: true,
    });
  };

  handleClosePreviewPopup = () => {
    this.setState({
      filePreviewPopup: false,
    });
  };
  handlerClosePopover = () => {
    this.setState({
      isShowPopover: "",
      scrollToElementId: "",
    });
  };

  getBalance = () => {
    const currencyAvailable = this.props.coupon.amount;
    let balance = formatNumAsCurrency({
      value: currencyAvailable,
      currency: USD,
      showIsZero: true,
    });

    if (this.props.i18n.locale === "ja") {
      let balanceJPY = formatNumAsCurrency({
        value: currencyAvailable * this.props.personalExchangeRate,
        currency: JPY,
        showIsZero: true,
      });

      balance = (
        <div style={{ display: "flex" }}>
          {balanceJPY}
          <div
            style={{ cursor: "pointer" }}
            title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.TITLE_YEN_AMOUNT", {
              usd: balance,
            })}
          >
            *
          </div>
        </div>
      );
    }

    return balance;
  };

  componentDidMount() {
    const uuid = getUuidFromUrl();
    this.loadAllLanguages().then(data => {
      uuid && this.getUnregisterUserDraft(uuid);
    });
    const { getCouponInfo, changeTableState, getExchangeRate } = this.props;
    const token = getCookie("auth_token");
    if (this.state.hasAI) window.localStorage.setItem("ai", "true");
    this.props.setTitle(I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PAGE_TITLE"));

    const beforeContactManager = document.getElementById("beforeContactManager");
    beforeContactManager && beforeContactManager.addEventListener("click", this.onOpenChatSupport);
    const beforeBlankContactManager = document.getElementById("beforeBlankContactManager");
    beforeBlankContactManager && beforeBlankContactManager.addEventListener("click", this.onSendWoopra);
    const underContactManager = document.getElementById("underContactManager");
    underContactManager && underContactManager.addEventListener("click", this.onOpenChatSupport);

    const couponHref = window.location.href;
    const couponCode = url.parse(couponHref, true, true).query.coupon;
    const discountCode = getDiscountFromUrl();
    if (discountCode) {
      this.applyDiscount(discountCode);
    }
    if (couponCode) {
      getExchangeRate("JPY", true).then(() => {
        getCouponInfo(couponCode).then(data => {
          console.log(data);
          if (
            data.status === "valid" ||
            (data.status === "redeemed" &&
              (data.policy === CouponPolicy.MULTI || data.policy === CouponPolicy.MULTI_NEW_ACCOUNTS))
          ) {
            this.setState({
              currencyAvailableWithSymbol: this.getBalance(),
            });
          }
        });
      });
    } else {
      getExchangeRate("JPY", true).then(() => {
        this.setState({
          currencyAvailableWithSymbol: this.getBalance(),
        });
      });
    }

    if (token) {
      window.location = "/customer/new";
    }

    if (!uuid) {
      const newOrderLocalStorage = localStorage.getItem("new_order");
      let newOrderDraft = {};

      if (newOrderLocalStorage) {
        newOrderDraft = JSON.parse(newOrderLocalStorage);
        const {
          sourceText,
          sourceHint,
          images,
          sourceLanguage,
          selectedTargetLanguage,
          advancedSettingsValue,
          files,
          advancedCategoryValue,
          sheetsData,
          googleSheetsDataFileName,
          googleDriveUrl,
          googleSheetsLink,
          uploadedFileSegmentsData,
          selectedService,
        } = newOrderDraft;
        console.log("files", files);

        if (files && files.length > 0) {
          files[0].deleteIcon = (
            <div styleName={"row.deleteFileIcon"}>
              <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
            </div>
          );
        }

        const createdDate = moment(newOrderDraft.createdDate);

        if (moment().diff(createdDate, "minutes") < 1440) {
          const hasFiles = !_.isEmpty(files);

          this.setState(
            {
              sourceText: sourceText,
              sourceLanguage: sourceLanguage,
              sourceHint: sourceHint,
              selectedTargetLanguage: selectedTargetLanguage,
              images: images,
              advancedSettingsValue: advancedSettingsValue,
              advancedCategoryValue: advancedCategoryValue,
              files: hasFiles ? files : [],
              sheetsData: sheetsData,
              googleSheetsDataFileName: googleSheetsDataFileName,
              googleDriveUrl: googleDriveUrl,
              googleSheetsLink: googleSheetsLink,
              uploadFileElements: hasFiles && files[0].type === "CSV" ? uploadedFileSegmentsData.fileElements : [],
              uploadFileParserInfo: hasFiles && files[0].type === "CSV" ? uploadedFileSegmentsData.parserInfo : {},
              selectedService,
            },
            () => {
              const volume =
                files && files.length ? files[0].volume : sourceText && sourceText.length ? sourceText.length : 0;
              this.state.sourceTitleRef.setCounter(volume);
              this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(volume) });
              changeTableState({
                ...newOrderDraft,
              });
            }
          );
          cancelUploadArray = newOrderDraft.images;
        } else {
          localStorage.setItem("new_order", "");
          setCookie("isNewOrderDraft", "");
        }
      }

      const discountCookie = getDiscountFromUrl() ? getDiscountFromUrl() : getCookie("discount");

      if (discountCookie) {
        setCookie("discount", discountCookie);
        this.props.setDiscountInfo(discountCookie).then(discount => {
          if (discount.status === "valid") {
            changeTableState({
              discount: {
                ...discount,
                code: discountCookie,
              },
            });
          }
        });
      }

      this.setState({
        sidebarTop: this.messageLink && this.messageLink.offsetHeight > 41 ? 165 : 150,
      });
    }

    if (this.props?.ws?.openWsPrice) {
      this.props.ws.openWsPrice();
    }
  }

  componentWillUnmount() {
    if (this.longSpreadsheetLoadingTimer) clearTimeout(this.longSpreadsheetLoadingTimer);
    this.saveDraft();
    if (this.props?.ws?.closeWsPrice) {
      this.props.ws.closeWsPrice();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filesTotalPrice, selectedService, priceSidebarInfo } = this.state;
    const {
      sourceText,
      sourceTextCounter,
      sourceHint,
      sourceLanguage,
      selectedTargetLanguage,
      images,
      advancedSettingsValue,
      discount,
      files,
      targetLanguages,
    } = this.props.tableState;

    const isWsPriceOpened = this.props.ws.isWSPriceOpened;

    const isPriceEmpty = filesTotalPrice === undefined || filesTotalPrice === null;

    const isSourceTextChanged =
      sourceTextCounter !== prevProps.tableState.sourceTextCounter || sourceText !== prevProps.tableState.sourceText;

    const isSourceLanguageChanged = sourceLanguage?.value !== prevProps.tableState.sourceLanguage?.value;
    const isSelectedTargetLanguageChanged = selectedTargetLanguage !== prevProps.tableState.selectedTargetLanguage;
    const isFilesChanged = files !== prevProps.tableState.files;
    const isDiscountChanged = discount !== prevProps.tableState.discount;
    const isSelectedServiceChanged = selectedService !== prevState.selectedService;

    const hasSourceTextOrFile = sourceTextCounter || files?.length;
    const hasSourceAndTargetLanguages = sourceLanguage?.value && !!Object.keys(selectedTargetLanguage).length;
    const isChanged =
      isSelectedTargetLanguageChanged ||
      isSourceLanguageChanged ||
      isSourceTextChanged ||
      isFilesChanged ||
      isDiscountChanged ||
      isSelectedServiceChanged;

    const hasNecessaryDataForCalculation = !!(hasSourceTextOrFile && hasSourceAndTargetLanguages);

    const shouldCalculateAfterChanges = hasNecessaryDataForCalculation && isChanged;
    const hasDataAndNoPrice = hasNecessaryDataForCalculation && isPriceEmpty;

    const shouldSendToCalculation = (shouldCalculateAfterChanges || hasDataAndNoPrice) && isWsPriceOpened;

    if (shouldSendToCalculation) {
      this.calculatePrice({ sourceTextCounter, sourceLanguage, selectedTargetLanguage });
    }

    const isPriceInSidebarEmpty =
      priceSidebarInfo ===
      formatNumAsCurrency({
        value: 0,
        currency: USD,
        showIsZero: true,
      });
    if (!hasNecessaryDataForCalculation && !isPriceInSidebarEmpty) {
      const priceUSD = formatNumAsCurrency({
        value: 0,
        currency: USD,
        showIsZero: true,
      });

      this.setState({ filesTotalPrice: null });
      this.setSidebarInfoPrice(priceUSD);
    }

    if (prevProps.ws.message !== this.props.ws.message) {
      const message = this.props.ws.message;
      const isCustomerPricesMessage = message?.customerPriceTotal;

      if (isCustomerPricesMessage) {
        this.setPricesFromWS(message, hasNecessaryDataForCalculation);
      }
    }

    if (
      (!prevProps.authClick && this.props.authClick) ||
      !_.isEqual(prevProps.sourceText, sourceText) ||
      !_.isEqual(prevProps.tableState.sourceHint, sourceHint) ||
      !_.isEqual(prevProps.sourceLanguage, sourceLanguage) ||
      !_.isEqual(prevProps.selectedTargetLanguage, selectedTargetLanguage) ||
      !_.isEqual(prevProps.images, images) ||
      !_.isEqual(prevProps.advancedSettingsValue, advancedSettingsValue) ||
      !_.isEqual(prevProps.discount, discount)
    ) {
      this.saveDraft();
    }

    if (targetLanguages !== prevProps.tableState.targetLanguages) {
      this.setLangsWithProofread(targetLanguages);
    }
  }

  setLangsWithProofread = languages => {
    const langsWithProofread = Object.keys(languages).reduce((acc, langCode) => {
      const lang = languages[langCode];
      const hasProofread = lang.sourcePairs[langCode];

      if (!hasProofread) return acc;

      return { ...acc, [langCode]: lang };
    }, {});

    this.setState({ langsWithProofread });
  };

  setPricesFromWS = (message, hasNecessaryDataForCalculation) => {
    const { needApplyTmPmDiscount, showBtnCalculate } = this.state;
    const filesTotalPrice = this.getFilesTotalPrices(message);

    this.setState(prev => ({
      ...prev,
      loadingDiscountTM: false,
      filesTotalPrice: hasNecessaryDataForCalculation ? filesTotalPrice : null,
    }));

    this.props.changeTableState({
      showBtnCalculate,
      needApplyTmPmDiscount,
      filesTotalPrice: hasNecessaryDataForCalculation ? filesTotalPrice : null,
    });

    const priceUSD = formatNumAsCurrency({
      value: hasNecessaryDataForCalculation ? filesTotalPrice?.priceWithDiscount?.usd : 0,
      currency: USD,
      showIsZero: true,
    });

    this.setSidebarInfoPrice(priceUSD);
  };

  getFilesTotalPrices = message => {
    const customerPriceTotal = getFormattedPriceCurrenciesAsNumber(message?.customerPriceTotal);
    const originalPriceTotal = getFormattedPriceCurrenciesAsNumber(message?.originalPriceTotal);
    const customerPriceWithoutDiscountAmountTotal = getFormattedPriceCurrenciesAsNumber(
      message?.customerPriceWithoutDiscountAmountTotal
    );
    const discountAmountTotal = getFormattedPriceCurrenciesAsNumber(message?.discountAmountTotal);
    const pairsTargetPrices = this.getPairsTargetPrices(message.pairInfo);

    const filesTotalPrice = {
      price: originalPriceTotal,
      priceWithDiscount: customerPriceTotal,
      customerPriceWithoutDiscountAmountTotal,
      discountSumm: discountAmountTotal,
      targetPrices: pairsTargetPrices,
    };

    return filesTotalPrice;
  };

  getPairsTargetPrices = pairs => {
    return pairs.map(pair => {
      const parsedPriceInfoUSD = pair.priceInfoUsd;
      const parsedPriceInfoJPY = pair.priceInfoAlt;

      const priceInfoUSD = getFormattedCustomerPricesAsNumber(parsedPriceInfoUSD);
      const priceInfoJPY = getFormattedCustomerPricesAsNumber(parsedPriceInfoJPY);

      return {
        priceInfoUSD,
        priceInfoJPY,
        lang: { label: I18n.t("LANGUAGES." + pair.targetLanguage) },
      };
    });
  };

  setDataFromDraftForTXT = (data, file) => {
    const selectedData = JSON.parse(data.draft.selected);

    file.encoding = selectedData.encoding;
    file.filesAsFormattedText = selectedData.filesAsFormattedText;
    file.draftId = data.draft.draftId;
  };

  setDataFromDraftForSpreadsheet = (data, file) => {
    const selected = JSON.parse(data.draft.selected);
    let countSelectedLists = 0;
    let countSelectedColumns = 0;
    selected.map(list => {
      countSelectedLists += list.partial || list.selected ? 1 : 0;
      list.columns.map(column => {
        countSelectedColumns += column.selected ? 1 : 0;
      });
    });
    file.countSelectedLists = countSelectedLists;
    file.countSelectedColumns = countSelectedColumns;
    file.sheetsData = selected;
  };

  getUnregisterUserDraft = async (uuid = "") => {
    const { changeTableState } = this.props;
    const { targetLanguages } = this.props.tableState;

    const data = await getDraftUnregisterRequest(uuid);

    data.draft.targetLanguagesList = data.draft.targetLanguagesList.reduce((acc, langValue) => {
      if (targetLanguages[langValue]) acc[langValue] = targetLanguages[langValue];
      return acc;
    }, {});

    const orderType =
      data.draft.format === "GOOGLE_SPREADSHEET" || data.draft.format === "GOOGLE_DOC"
        ? "googleFile"
        : data.draft.format === "Text"
          ? "text"
          : "file";

    const fileUuid = data.draft.fileUuid;

    if (fileUuid) {
      const fileMetadata = await this.props.getFileMetadata(fileUuid);

      const file = {
        ...fileMetadata.file,
        fileSegmentsData: fileMetadata.fileSegmentsData,
        language: data.draft.sourceLanguage,
      };

      file.type = data.draft.format;
      file.volume = data.draft.volume;
      file.content = data.draft.sourceText.split("\n");

      if (file.type === "TXT" && data.draft.selected) {
        this.setDataFromDraftForTXT(data, file);
      }

      if (file.type === "GOOGLE_SPREADSHEET" && data.draft.selected.length) {
        this.setDataFromDraftForSpreadsheet(data, file);
      }

      if (file.type === "CSV" || file.type === "XML") {
        this.setCSVandXMLInitialSettings(data, file);
      }

      data.file = file;
    }

    if (data?.file) {
      const closeIcon = (
        <div styleName={"row.deleteFileIcon"}>
          <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
        </div>
      );
      data.file.deleteIcon = closeIcon;
    }
    const isAI = data.draft.translationService === 2;

    const state = {
      selectedService: SERVICE_VALUE_BY_NUMBER[data.draft.translationService],
      sourceText: data.draft.sourceText,
      sourceHint: data.draft.messageText,
      sourceLanguage: {
        value: data.draft.sourceLanguage,
        label: I18n.t("LANGUAGES." + data.draft.sourceLanguage),
      },
      files: data?.file ? [data.file] : [],
      selectedTargetLanguage: data.draft.targetLanguagesList,
      advancedCategoryValue: data.draft.advancedOptions.translationCategory,
      advancedSettingsValue: translationTypeList[data.draft.advancedOptions.translationType],
      maxTranslationsLength: data.draft.advancedOptions.maxLength,
      images: data.attachmentsList,
      needApplyTmPmDiscount: data.draft.needApplyTmPmDiscount,
      sourceTextCounter: data.draft.volume,
      filesTotalPrice: null,
      orderType,
      googleDriveUrl: data?.draft?.googleDriveUrl,
      googleSheetsLink: data?.file?.fileUrl,
      googleSheetsDataFileName: data?.file?.filename,
      waitOrdersGroupToNotified: data?.draft?.waitOrdersGroupToNotified,
      sheetsData: data?.file?.sheetsData,
    };

    if (isAI) window.localStorage.setItem("ai", "true");

    this.setState({ ...state });
    changeTableState({ ...state, id: data.draft.draftId });

    this.state.sourceTitleRef &&
      this.state.sourceTitleRef.setCounter &&
      this.state.sourceTitleRef.setCounter(data.draft.volume);
    this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(data.draft.volume) });
  };

  setCSVandXMLInitialSettings = (data, file) => {
    const { changeTableState } = this.props;
    const selected = JSON.parse(data.draft.selected);
    const { selected: initialFileSettings } = selected;

    const { filesAsFormattedText, parserInfo, fileElements } = initialFileSettings;

    this.setState({
      uploadFileParserInfo: parserInfo,
      uploadFileElements: fileElements,
    });

    file.filesAsFormattedText = filesAsFormattedText;

    if (file.type === "CSV") {
      changeTableState({
        uploadedFileSegmentsData: selected.selected,
      });
      file.countSelectedColumns = selected.columnsCount;
    }

    if (file.type === "XML") {
      changeTableState({
        uploadedFileSegmentsData: selected.selected,
      });
      file.countSelectedNodes = selected.countSelectedNodes;
      file.countSelectedAttributes = selected.countSelectedAttributes;
    }
  };

  handleDragEnter = () => {
    this.nav && this.nav.blockRefList["context"].classList.add("highlight");
  };

  handleDragLeave = () => {
    this.nav && this.nav.blockRefList["context"].classList.remove("highlight");
  };

  handleChangeSourceHint = ({ target }) => {
    const sourceHint = target.value;
    target && this.changeSourceHint(sourceHint);
  };

  changeSourceHint = _.debounce(text => {
    this.setState({ sourceHint: text }, () => {
      this.setSourceHintToStoreDebounce(text);
    });
  }, 100);

  setSourceHintToStoreDebounce = _.debounce(text => {
    this.saveSourceHintToStore(text);
  }, 400);

  saveSourceHintToStore = text => {
    const { changeTableState } = this.props;
    changeTableState({
      sourceHint: text,
    });
  };

  handlerDeleteImage = async image => {
    const { deleteImage, changeTableState } = this.props;
    const { images } = this.props.tableState;
    const { id } = image;
    let imagesTmp = [...images];

    for (let i = 0; i < cancelUploadArray.length; i++) {
      if (cancelUploadArray[i].count === image.count) {
        imagesTmp[i] = {};
        if (imageLoading && imageLoading.count === cancelUploadArray[i].count) {
          imageLoading = "";
        }
      }
    }

    changeTableState({
      images: imagesTmp,
    });

    cancelUploadArray = imagesTmp;

    try {
      id &&
        deleteImage(id).then(() => {
          this.saveDraft();
        });
    } catch (e) {
      console.log(e);
    }
  };

  handleRetry = async (image, arrayCount) => {
    await this.addImage([image], arrayCount);
  };

  addImage = async (selectedFile, imageStack) => {
    const { changeTableState } = this.props;

    for (let i = 0; i < selectedFile.length; i++) {
      const file = selectedFile[i];
      let img = new Image();

      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let imageSrc = img.src;
        let originalWidth = img.width;
        let originalHeight = img.height;

        const error = checkImage(file, limitImageSize, originalWidth, originalHeight);

        let cancelUploadElement = {
          id: null,
          file: null,
          count: i,
          width: originalWidth,
          height: originalHeight,
          originalWidth,
          originalHeight,
          uploadImageError: error,
          selectedFile: file,
          name: file.name,
          size: file.size,
        };

        if (imageStack >= 0) {
          cancelUploadArray[imageStack] = cancelUploadElement;
        } else {
          cancelUploadArray.push(cancelUploadElement);
        }

        changeTableState({
          images: cancelUploadArray,
        });

        if (!error) {
          !!file && this.uploadImage(file, file.type, file.name, i, imageSrc, originalWidth, originalHeight);
        }
      };
    }
  };

  uploadImage = async (file, type, name, count, urlObj, originalWidth, originalHeight) => {
    let currentImage = "";
    const { changeTableState, uploadImage } = this.props;
    let newImage = { id: null, file: null, count, uploadImageError: "" };
    const imageLoadingSuccess = (i, cancelUploadArray, resp, imageFile) => {
      cancelUploadArray[i].file = imageFile;
      newImage = { id: resp, file: imageFile, count };
      cancelUploadArray[i].id = resp;
      cancelUploadArray[i].count = resp;
      cancelUploadArray[i].notAuth = true;

      changeTableState({
        images: cancelUploadArray,
      });
    };
    const imageLoadingError = (i, cancelUploadArray) => {
      cancelUploadArray[i].uploadImageError = I18n.t("ERRORS.IMAGE_LOADING", {
        fileName: name,
      });
      cancelUploadArray[i].isRetry = true;

      changeTableState({
        images: cancelUploadArray,
      });
    };

    for (let i = 0; i < cancelUploadArray.length; i++) {
      if (
        !cancelUploadArray[i].id &&
        !cancelUploadArray[i].uploadImageError &&
        cancelUploadArray[i].count === newImage.count
      ) {
        currentImage = cancelUploadArray[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          uploadImage(reader.result, type, name, originalWidth, originalHeight).then(id => {
            if (id) {
              const imageSrc = process.env.FRONTEND_URL + "/attachments/" + id;

              if (currentImage === cancelUploadArray[i]) {
                imageLoadingSuccess(i, cancelUploadArray, id, imageSrc);
              } else {
                for (let j = 0; j < cancelUploadArray.length; j++) {
                  if (currentImage === cancelUploadArray[j]) {
                    imageLoadingSuccess(j, cancelUploadArray, id);
                  }
                }
              }
            } else {
              for (let i = 0; i < cancelUploadArray.length; i++) {
                if (cancelUploadArray[i].count === newImage.count) {
                  imageLoadingError(i, cancelUploadArray);
                }
              }
            }
          });
        };
      }
    }
  };

  handleAddImage = async event => {
    event.preventDefault();
    const { target, dataTransfer } = event;
    const selectedFile = target.files ? target.files : dataTransfer.files;

    this.nav && this.nav.blockRefList["context"].classList.remove("highlight");

    this.setState({
      uploadImageError: "",
    });

    await this.addImage(selectedFile);
  };

  sourceLanguageDetectedFromFile = () => {
    const { changeTableState } = this.props;
    const { sourceLanguages, selectedTargetLanguage } = this.props.tableState;
    if (this.state.files[0] && this.state.files[0].language) {
      const language = this.state.files[0].language;
      const languageTmp = language === PORTUGES_LANG_VALUE ? PORTUGES_DEFAULT_LANG_VALUE : language;
      const isExistDetectedLanguage =
        _.findKey(sourceLanguages, ["value", language]) && languageTmp !== UNDEFINED_LANG_VALUE;
      const sourceLanguage = isExistDetectedLanguage
        ? {
            value: languageTmp,
            label: I18n.t("LANGUAGES." + languageTmp) + " — " + newOrderPageLocal("DETECTED"),
          }
        : {
            value: "",
            label: "",
          };
      const filteredSelectedTargetLanguage = _.forOwn(selectedTargetLanguage, target => {
        if (!target.sourcePairs[language] && isExistDetectedLanguage) {
          delete selectedTargetLanguage[target.id];
        }
      });

      this.setState({
        placeholderMenu: {
          visible: false,
          label: newOrderPageLocal("SELECT"),
        },
        sourceLanguage,
      });

      changeTableState({
        permissionAutoDetectedLanguage: false,
        isAutoDetectedLanguage: true,
        selectedTargetLanguage: filteredSelectedTargetLanguage,
        sourceLanguage: sourceLanguage,
      });
      //if selectedService === "PROOFREAD", source+target will be setted in
      //this.checkAvailableSourceTargetPairs(sourceLanguage); func
      this.checkAvailableSourceTargetPairs(sourceLanguage);
    } else {
      this.setState({
        placeholderMenu: {
          visible: true,
          label: newOrderPageLocal("SELECT"),
        },
        sourceLanguage: {},
      });

      changeTableState({
        sourceLanguage: {},
      });
    }
  };

  handleSourceLanguageDetected = isNotVisible => {
    const { sourceText, files } = this.state;

    if (sourceText || (files && files.length > 0)) {
      if (!isNotVisible) {
        this.setState(
          {
            placeholderMenu: {
              visible: true,
              label: newOrderPageLocal("DETECTING"),
            },
          },
          () => {
            if (sourceText) {
              this.sourceLanguageDetected();
            } else {
              this.sourceLanguageDetectedFromFile();
            }
          }
        );
      } else {
        this.sourceLanguageDetected();
      }
    }
  };

  onSourceLanguageChange = (e, menuRef, detectBtn) => {
    const { focusDetectedBtn } = this.state;
    const { menuIsOpen } = menuRef.state;
    const isDetectedBtnFocusOff = !focusDetectedBtn;
    if (focusDetectedBtn && menuIsOpen) {
      if (isUpArrow(e) || isDownArrow(e) || isEnter(e) || isSpace(e)) {
        e.preventDefault();
        if (isEnter(e) || isSpace(e)) {
          this.handleSourceLanguageDetected();
          menuRef.blur();
        }
      }
    }

    if (menuIsOpen) {
      if (isTab(e)) {
        if (isDetectedBtnFocusOff) {
          e.preventDefault();
          this.setState({ focusDetectedBtn: true });
          detectBtn.focus();
        } else {
          e.preventDefault();
          this.setState({ focusDetectedBtn: false });
        }
      }
    }
  };

  checkAndSetLanguagesForProofreading = (sourceLang, service) => {
    const { changeTableState } = this.props;
    const { langsWithProofread } = this.state;

    const target = langsWithProofread[sourceLang.value];

    if (!target) {
      changeTableState({
        sourceLanguage: { value: null },
        selectedTargetLanguage: {},
        filesTotalPrice: null,
      });
      return;
    }

    changeTableState({
      sourceLanguage: sourceLang,
      selectedTargetLanguage: { [target.id]: target },
      filesTotalPrice: null,
    });
  };

  checkAvailableSourceTargetPairs = (sourceLang, service = "") => {
    const { selectedService } = this.state;
    const { changeTableState, tableState } = this.props;
    const { selectedTargetLanguage, sourceLanguage } = tableState;

    const translationService = service ? service : selectedService;
    const sourceLanguageItem = sourceLang ? sourceLang : sourceLanguage;

    const isAISelectedService = translationService === "AI";
    const isProofreading = translationService === "PROOFREAD";

    if (isProofreading) {
      this.checkAndSetLanguagesForProofreading(sourceLanguageItem, translationService);
      return;
    }

    const copySelectedTargetLanguage = structuredClone(selectedTargetLanguage);
    const filteredSelectedTargetLanguage = _.forOwn(copySelectedTargetLanguage, language => {
      const hasSourceTargetPair = language.sourcePairs[sourceLanguageItem.value];
      const isProofread = language.value === sourceLanguageItem.value;

      if (isAISelectedService && !(hasSourceTargetPair && language.sourcePairs[language.value])) {
        delete copySelectedTargetLanguage[language.id];
        return;
      }

      if (isProofread) delete copySelectedTargetLanguage[language.id];

      if (!hasSourceTargetPair) {
        delete copySelectedTargetLanguage[language.id];
      }
    });

    changeTableState({
      selectedTargetLanguage: filteredSelectedTargetLanguage,
      filesTotalPrice: null,
    });
  };

  handleChangeSourceLanguage = item => {
    const { changeTableState } = this.props;

    this.setState({
      userSelectLanguage: true,
      placeholderMenu: {
        visible: false,
        label: newOrderPageLocal("SELECT"),
      },
      sourceLanguage: item,
      filesTotalPrice: null,
    });

    changeTableState({
      sourceLanguage: item,
      permissionAutoDetectedLanguage: false,
    });

    this.checkAvailableSourceTargetPairs(item);
  };

  sourceLanguageDetected = () => {
    const { sourceText } = this.state;
    const { detectLanguage, changeTableState } = this.props;
    const { sourceLanguages } = this.props.tableState;
    detectLanguage({ text: sourceText }).then(response => {
      const language = response.language;
      const languageTmp = language === PORTUGES_LANG_VALUE ? PORTUGES_DEFAULT_LANG_VALUE : language;
      const isExistDetectedLanguage =
        _.findKey(sourceLanguages, ["value", language]) && languageTmp !== UNDEFINED_LANG_VALUE;
      const sourceLanguage = isExistDetectedLanguage
        ? {
            value: languageTmp,
            label: I18n.t("LANGUAGES." + languageTmp) + " — " + newOrderPageLocal("DETECTED"),
          }
        : {
            value: "",
            label: "",
          };

      this.setState({
        placeholderMenu: {
          visible: false,
          label: newOrderPageLocal("SELECT"),
        },
        sourceLanguage,
        filesTotalPrice: null,
      });

      changeTableState({
        permissionAutoDetectedLanguage: false,
        isAutoDetectedLanguage: true,
        sourceLanguage: sourceLanguage,
      });
      //check comment in NewOrderGeneral component in the same place
      this.checkAvailableSourceTargetPairs(sourceLanguage);
    });
  };

  onBlurDetectBtn = () => {
    this.setState({ focusDetectedBtn: false });
  };

  getSortSourceLanguages = languages => {
    const { selectedService, langsWithProofread } = this.state;
    let langsToSort = languages;

    if (selectedService === "PROOFREAD") langsToSort = langsWithProofread;

    return _.sortBy(Object.values(langsToSort), [
      ({ label }) => {
        return label;
      },
    ]);
  };

  getSortTargetLanguages = languages => {
    let isExistProofreading = false;
    const languagesTemp = { ...languages };

    if (this.props.tableState.sourceLanguage.value) {
      isExistProofreading = languagesTemp[this.props.tableState.sourceLanguage.value];
      isExistProofreading && delete languagesTemp[isExistProofreading.value];
    }

    const sortedLanguages = _.sortBy(Object.values(languagesTemp), [
      ({ label }) => {
        return label;
      },
    ]);

    return sortedLanguages;
  };

  debounceSaveDraft = _.debounce(() => {
    this.saveDraft();
  }, 400);

  resetSelectedTargetLanguages = () => {
    const { changeTableState } = this.props;
    let tmpSelectedTargetLanguage = {};
    this.setState({
      selectedTargetLanguage: tmpSelectedTargetLanguage,
      filesTotalPrice: null,
      discountsTMList: [],
    });

    changeTableState({
      selectedTargetLanguage: tmpSelectedTargetLanguage,
      filesTotalPrice: null,
      discountsTMList: [],
    });
    this.debounceSaveDraft();
  };

  calculateSourceTextChars = splittedSourceText => {
    const isTextareaFocused =
      this.sourceInput &&
      this.sourceInput.textarea &&
      this.sourceInput.textarea.current &&
      this.sourceInput.textarea.current.contains(document.activeElement);

    const regExp = new RegExp(/([\u200B-\u200D])|(^\h*)|(\h*$)/g);
    const result = splittedSourceText
      .map((elem, index) => {
        if (elem.replace(regExp, "").trim().length > 0) {
          if (index === splittedSourceText.length - 1 && isTextareaFocused) {
            return elem.trimStart();
          }

          return elem.trim();
        } else {
          return elem.replace(regExp, "").trim();
        }
      })
      .join("").length;

    return result;
  };

  handleChangeSourceText = ({ target }) => {
    const { permissionAutoDetectedLanguage, isAutoDetectedLanguage } = this.props.tableState;
    const { changeTableState } = this.props;
    const sourceText = target.value;
    const splittedSourceText = sourceText.split("\n");
    const sourceTextCharsAmount = this.calculateSourceTextChars(splittedSourceText);

    changeTableState({
      orderType: "text",
    });

    this.state.sourceTitleRef &&
      this.state.sourceTitleRef.setCounter &&
      this.state.sourceTitleRef.setCounter(sourceTextCharsAmount);
    this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(sourceTextCharsAmount) });

    this.changeSourceText(sourceText, sourceTextCharsAmount);

    !isAutoDetectedLanguage && permissionAutoDetectedLanguage && this.autoDetectedLanguageDebounce();
  };

  autoDetectedLanguageDebounce = _.debounce(() => {
    this.handleSourceLanguageDetected();
  }, AUTO_DETECT_LANGUAGE_DELAY);

  changeSourceText = _.debounce((text, amount) => {
    const { isAutoDetectedLanguage } = this.props.tableState;
    const { userSelectLanguage } = this.state;
    const { changeTableState } = this.props;

    this.setState({ sourceText: text, filesTotalPrice: null });

    this.setSourceTextToStoreDebounce(text, amount);
    if (text && !userSelectLanguage && !isAutoDetectedLanguage) {
      changeTableState({
        permissionAutoDetectedLanguage: true,
      });
    } else if (!text && !userSelectLanguage) {
      changeTableState({
        isAutoDetectedLanguage: false,
        permissionAutoDetectedLanguage: true,
      });
    }
  }, 100);

  setSourceTextToStoreDebounce = _.debounce((text, amount) => {
    this.saveSourceTextToStore(text, amount);
  }, 400);

  saveSourceTextToStore = (text, amount) => {
    const { changeTableState } = this.props;
    changeTableState({
      sourceText: text,
      sourceTextCounter: amount,
      filesTotalPrice: null,
    });
  };

  getRefTitle = ref => {
    this.setState({
      sourceTitleRef: ref,
    });
  };

  getSidebarInfoSource = ref => {
    const volume =
      this.state.files.length > 0 ? this.state.files[0].volume : this.state.sourceText.replace(/\r?\n/g, "").length;
    this.setState(
      {
        sidebarInfoCounterRef: ref,
      },
      () => {
        this.state.sourceTitleRef.setCounter && this.state.sourceTitleRef.setCounter(volume);
        this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(volume) });
      }
    );
  };

  getSidebarInfoPrice = ref => {
    this.setState({
      sidebarInfoPriceRef: ref,
    });
  };

  setSidebarInfoPrice = price => {
    this.setState({ priceSidebarInfo: price });
  };

  loadAllLanguages = () => {
    const { getCustomerRates } = this.props;
    return getCustomerRates({
      offset: OFFSET_CUSTOMER_RATES,
      limit: LIMIT_CUSTOMER_RATES,
      search: "",
      sortField: SORT_FIELD,
      sortOrder: DESC,
      targetLanguage: "",
      sourceLanguage: "",
      locale: "",
    }).then(({ ratesList }) => {
      this.mapRatesToLanguages(ratesList);
    });
  };

  mapRatesToLanguages = ratesList => {
    const { changeTableState } = this.props;
    const languages = mapRatesToLanguages(ratesList);
    const sourceLanguages = languages.sourceLanguages;
    const targetLanguages = languages.targetLanguages;
    changeTableState({ sourceLanguages, targetLanguages });
  };

  handleSelectTargetLanguage = language => {
    const { selectedTargetLanguage } = this.props.tableState;
    const { changeTableState } = this.props;
    let tmpSelectedTargetLanguage = structuredClone(selectedTargetLanguage);

    tmpSelectedTargetLanguage[language.value]
      ? delete tmpSelectedTargetLanguage[language.value]
      : (tmpSelectedTargetLanguage[language.value] = language);

    this.setState({
      selectedTargetLanguage: tmpSelectedTargetLanguage,
      filesTotalPrice: null,
    });

    changeTableState({
      selectedTargetLanguage: tmpSelectedTargetLanguage,
      filesTotalPrice: null,
    });
  };

  clickDisabledLanguage = targetLanguage => {
    const { me } = this.props;
    const { sourceLanguage } = this.props.tableState;

    woopra.track("Disabled language", {
      id: me && me.id,
      email: me && me.email,
      env: process.env.SERVER_NAME,
      "source language": sourceLanguage.value,
      "target language": targetLanguage.value,
    });
  };

  loadingImage = () => {
    const { images } = this.props.tableState;
    imageLoading = _.find(images, o => {
      return o.id === null;
    });
  };

  saveDraft = () => {
    const { selectedService } = this.state;
    const {
      id,
      sourceText,
      sourceTextCounter,
      sourceHint,
      sourceLanguage,
      selectedTargetLanguage,
      images,
      advancedSettingsValue,
      advancedCategoryValue,
      discount,
      files,
      maxTranslationsLength,
      sheetsData,
      googleDriveUrl,
      googleSheetsLink,
      googleSheetsDataFileName,
      orderType,
      uploadedFileSegmentsData,
    } = this.props.tableState;

    let filesTmp = [];
    const hasFiles = files && files.length > 0;

    if (hasFiles) {
      filesTmp = [{ ...files[0] }];
      filesTmp[0].deleteIcon = true;
    }

    // if (sourceText && !_.isEmpty(sourceLanguage) && !_.isEmpty(selectedTargetLanguage)) {
    try {
      localStorage.setItem(
        "new_order",
        JSON.stringify({
          sourceText,
          sourceTextCounter,
          sourceHint,
          sourceLanguage,
          selectedTargetLanguage,
          images,
          advancedSettingsValue: advancedSettingsValue,
          advancedCategoryValue: advancedCategoryValue,
          createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          discount,
          files: hasFiles ? filesTmp : [],
          maxTranslationsLength,
          sheetsData: sheetsData,
          googleSheetsDataFileName: googleSheetsDataFileName,
          googleDriveUrl: googleDriveUrl,
          googleSheetsLink: googleSheetsLink,
          orderType: orderType,
          uploadedFileSegmentsData: uploadedFileSegmentsData,
          selectedService,
          id,
        })
      );

      setCookie("isNewOrderDraft", true);
    } catch (e) {
      console.log(e);
    }

    // } else {
    //   localStorage.setItem('new_order', "");
    //   setCookie("isNewOrderDraft", "")
    // }
  };

  closeErrorMessage = message => {
    const { validateErrors } = this.state;
    const newErrors = _.remove(validateErrors, validateErrors[message]);
    this.setState({ validateErrors: newErrors });
  };

  handleCreateOrder = () => {
    const { sourceText, filesTotalPrice, sourceHint } = this.state;
    const { images, sourceLanguage, selectedTargetLanguage } = this.props.tableState;
    const orderData = { selectedTargetLanguage, sourceText, sourceLanguage, filesTotalPrice, sourceHint, images };

    cursorLocalWait(true);

    const validatingErrorsObject = validateNewOrder(
      orderData,
      this.closeErrorMessage,
      this.state.files && this.state.files.length > 0,
      this.state.files
    );

    this.setState(
      {
        validateErrors: validatingErrorsObject,
        scrollToElementId: validatingErrorsObject.blockId,
        isCreateOrder: true,
        onFocusSource: validatingErrorsObject.isSourceTextNotExist,
      },
      () => {
        this.setState({
          scrollToElementId: "",
        });
      }
    );

    if (_.isEmpty(validatingErrorsObject)) {
      if (this.state.files && this.state.files.length > 0) {
        this.checkOrderFromFileBeforeCreate();
      } else {
        this.checkSourceLanguageWarning();
      }
    } else {
      this.sendTranslationWoopra();
      cursorLocalWait(false);
    }
  };

  getSelectedPayloadForDraftSaving = () => {
    const { tableState } = this.props;
    const { files } = this.state;
    const { sheetsData, uploadedFileSegmentsData } = tableState;

    if (sheetsData) return JSON.stringify(sheetsData);
    const type = files.length && files[0].type;

    if (!files?.length) return null;

    if (type === "XML") {
      return JSON.stringify({
        selected: uploadedFileSegmentsData,
        countSelectedNodes: files[0].countSelectedNodes,
        countSelectedAttributes: files[0].countSelectedAttributes,
      });
    }

    if (type === "CSV") {
      return JSON.stringify({
        selected: uploadedFileSegmentsData,
        columnsCount: files[0].countSelectedColumns,
      });
    }
    //todo: switch or { type: () => payload } => obj[type]()
    if (type === "TXT") {
      return JSON.stringify({
        encoding: files[0].encoding,
        filesAsFormattedText: files[0].filesAsFormattedText,
      });
    }
  };

  handleFinishLaterSubmitClick = async email => {
    const { sourceText, sourceHint, files, needApplyTmPmDiscount, selectedService } = this.state;
    const { tableState } = this.props;
    const {
      sourceLanguage,
      selectedTargetLanguage,
      images,
      advancedSettingsValue,
      discount,
      maxTranslationsLength,
      advancedCategoryValue,
      id,
    } = tableState;

    const discountCode = discount && discount.code ? discount.code : "";
    let imagesIds = [];
    _.forEach(images, image => {
      image.uuid && imagesIds.push(image.uuid);
      image.id && imagesIds.push(image.id);
    });
    const targetLanguageList = Object.keys(selectedTargetLanguage);
    const waitOrdersGroupToNotified = targetLanguageList.length > 1 ? this.state.waitOrdersGroupToNotified : false;
    const messageText = _.trim(sourceHint);
    const TONE_TO_ENUM_VALUE = {
      TRANSLATOR_GUESS: "ORDER_TYPE_UNDEFINED",
      FORMAL: "ORDER_TYPE_FORMAL",
      INFORMAL: "ORDER_TYPE_INFORMAL",
    };
    const uuids = files?.length && files[0].uuid;

    const request = {
      translationService: SERVICE_NUMBER_BY_VALUE[selectedService],
      sourceText,
      draftId: id,
      sourceLanguage: sourceLanguage.value,
      targetLanguageList: targetLanguageList,
      messageText,
      imagesIds,
      translationType: TONE_TO_ENUM_VALUE[advancedSettingsValue],
      discountCode,
      maxTranslationsLength,
      advancedCategoryValue,
      waitOrdersGroupToNotified,
      uuids,
      selected: this.getSelectedPayloadForDraftSaving(),
      needApplyTmPmDiscount: needApplyTmPmDiscount,
      email,
      locale: this.props.i18n.locale,
    };

    try {
      const data = await saveDraftUnregisterRequest(request);
      return true;
    } catch (e) {
      console.log("error is happened", { e });
      return false;
    }
  };

  setIsPopupFinishLaterOpened = (value = false) => {
    this.setState({ isPopupFinishLaterOpened: value });
  };

  sendTranslationWoopra(sent = "No") {
    const {
      sourceText,
      selectedTargetLanguage,
      sourceLanguage,
      sourceHint,
      sidebarInfoPriceRef,
      files,
      priceSidebarInfo,
    } = this.state;
    const { sourceTextCounter } = this.props.tableState;
    const sourceTextPart = sourceText && (sourceText.length > 256 ? limit(sourceText, 256) : sourceText);

    let targetLanguagesArray = "";
    const selectedTargetLanguageArray = selectedTargetLanguage ? Object.values(selectedTargetLanguage) : [];

    selectedTargetLanguageArray.map(item => {
      targetLanguagesArray = targetLanguagesArray + (targetLanguagesArray !== "" ? ", " : "") + item.value;
    });
    let woopraFormat = "Text";
    if (files[0]) {
      switch (files[0].type) {
        case "GOOGLE_DOC":
          woopraFormat = "Google docs";
          break;
        case "GOOGLE_SPREADSHEET":
          woopraFormat = "Google sheets";
          break;
        case "STRINGS":
          woopraFormat = ".strings";
          break;
        case "JSON":
          woopraFormat = "json";
          break;
        case "HTML":
          woopraFormat = "html";
          break;
      }
    }
    targetLanguagesArray = "[" + targetLanguagesArray + "]";
    const data = {
      source: sourceLanguage && sourceLanguage.value ? sourceLanguage.value : "not selected",
      target: selectedTargetLanguageArray.length ? targetLanguagesArray : "not selected",
      volume: sourceTextCounter ? sourceTextCounter : files[0] && files[0].volume ? files[0].volume : "",
      price: priceSidebarInfo || "",
      snippet: sourceTextPart ? sourceTextPart : "",
      hint: sourceHint ? sourceHint : "",
      sent,
      event: "Hit send for translation",
      env: process.env.SERVER_NAME,
      format: woopraFormat,
    };
    woopra.track("Hit send for translation", data);
  }

  checkOrderFromFileBeforeCreate = () => {
    cursorLocalWait(false);
    if (imageLoading && !imageLoading.uploadImageError) {
      const heightTopScreenToElement = 90;
      const element = this.image && ReactDOM.findDOMNode(this.image);
      const boundingClientRect = element && element.getBoundingClientRect();
      const top = boundingClientRect && boundingClientRect.top;
      this.sendTranslationWoopra();

      element &&
        window.scrollBy(0, top - heightTopScreenToElement, {
          behavior: "smooth",
        });
    } else {
      imageLoading = "";
      document.activeElement.blur();

      const { files } = this.state;
      const { tableState } = this.props;
      const { sourceLanguage } = tableState;
      const selectedLanguage = sourceLanguage.value;
      const textAcceptCreateOrder = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SOURCE_TEXT_DOESNT_LIKE_SELECTED", {
        sourceLanguage: I18n.t("LANGUAGES." + selectedLanguage),
      });

      selectedLanguage !== files[0].language
        ? this.openWarningDialog(textAcceptCreateOrder)
        : this.checkWarningsForOrderFromFile();
    }
  };

  checkWarningsForOrderFromFile = () => {
    const { files } = this.state;
    const { sourceLanguage, selectedTargetLanguage } = this.props.tableState;
    const { detectLanguage } = this.props;
    const { popupMessages } = this.state;

    detectLanguage({ fileUuid: files[0].uuid }).then(response => {
      const language = response.language;
      const warningMessage =
        language !== ENGLISH_EN_EN_LANG_VALUE &&
        sourceLanguage.value !== language &&
        getWarningMessage(sourceLanguage, selectedTargetLanguage, language, true);

      warningMessage
        ? this.setState({
            dialogOpen: true,
            dialogTitle: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.TITLE_POPUP"),
            popupMessages: { ...popupMessages, hintErrors: true },
            textAcceptCreateOrder: warningMessage,
            textAccept: I18n.t("BUTTONS.YES"),
          })
        : this.createOrder();
    });
  };

  checkSourceLanguageWarning = () => {
    if (imageLoading && !imageLoading.uploadImageError) {
      const heightTopScreenToElement = 90;
      const element = this.image && ReactDOM.findDOMNode(this.image);
      const boundingClientRect = element && element.getBoundingClientRect();
      const top = boundingClientRect && boundingClientRect.top;
      cursorLocalWait(false);
      this.sendTranslationWoopra();

      element &&
        window.scrollBy(0, top - heightTopScreenToElement, {
          behavior: "smooth",
        });
    } else {
      imageLoading = "";
      document.activeElement.blur();

      const { sourceText } = this.state;
      const { detectLanguage, tableState } = this.props;
      const { sourceLanguage } = tableState;
      const selectedLanguage = sourceLanguage.value;
      const textAcceptCreateOrder = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SOURCE_TEXT_DOESNT_LIKE_SELECTED", {
        sourceLanguage: I18n.t("LANGUAGES." + selectedLanguage),
      });

      detectLanguage({ text: sourceText }).then(({ language }) => {
        cursorLocalWait(false);
        selectedLanguage !== language
          ? this.openWarningDialog(textAcceptCreateOrder)
          : this.checkCommentariesWarnings();
      });
    }
  };

  openWarningDialog = textAcceptCreateOrder => {
    const { popupMessages } = this.state;

    this.setState({
      dialogOpen: true,
      dialogTitle: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.TITLE_POPUP"),
      popupMessages: { ...popupMessages, sourceLangErrors: true },
      textAcceptCreateOrder: textAcceptCreateOrder,
      textAccept: I18n.t("BUTTONS.YES"),
    });
  };

  checkCommentariesWarnings = () => {
    const { sourceHint } = this.state;
    const { sourceLanguage, selectedTargetLanguage } = this.props.tableState;
    const { detectLanguage } = this.props;
    const { popupMessages } = this.state;
    const isNoComments = !sourceHint;
    if (isNoComments) {
      return this.createOrder();
    }
    if (sourceHint) {
      detectLanguage({ text: sourceHint }).then(response => {
        const language = response.language;
        const warningMessage =
          language !== ENGLISH_EN_EN_LANG_VALUE &&
          sourceLanguage.value !== language &&
          getWarningMessage(sourceLanguage, selectedTargetLanguage, language);

        warningMessage
          ? this.setState({
              dialogOpen: true,
              dialogTitle: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.TITLE_POPUP"),
              popupMessages: { ...popupMessages, hintErrors: true },
              textAcceptCreateOrder: warningMessage,
              textAccept: I18n.t("BUTTONS.YES"),
            })
          : this.createOrder();
      });
    } else {
      this.handlerCreateOrder();
    }
  };

  createOrder = () => {
    const { paymentMethodValue, amount, isShowAddFundsBlock } = this.state;

    if ((isShowAddFundsBlock && paymentMethodValue.value !== "CO2") || amount) {
      localStorage.setItem(
        "add_funds",
        JSON.stringify({
          addFunds: {
            paymentMethod: paymentMethodValue,
            amount,
          },
        })
      );
    }
    this.saveDraft();
    const getNavigateUrl = getCouponFromUrl()
      ? "login?coupon=" + getCouponFromUrl()
      : getDiscountFromUrl()
        ? "login?discount=" + getDiscountFromUrl()
        : "login";
    this.props.router.navigate(getNavigateUrl);
    this.setState({
      dialogSignInOpen: true,
    });
  };

  handleAddNewCardClick = () => {
    const getNavigateUrl = getCouponFromUrl()
      ? "login?coupon=" + getCouponFromUrl()
      : getDiscountFromUrl()
        ? "login?discount=" + getDiscountFromUrl()
        : "login";
    this.props.router.navigate(getNavigateUrl);
    this.setState({
      dialogSignInOpen: true,
    });
  };

  handlePopupClose = () => {
    const { popupMessages } = this.state;
    this.sendTranslationWoopra();
    this.setState({
      popupMessages: { ...popupMessages, sourceLangErrors: false },
      dialogSignInOpen: false,
      dialogOpen: false,
    });
  };

  onOpenChatForLanguages = () => {
    const template = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.NEED_LANGUAGES_TO_BE_ADDED_CHAT_MESSAGE");

    showNewMessageChat(template);
  };

  onOpenChatSupport = () => {
    this.onSendWoopra();
    const template = I18n.t("FAQ_CUSTOMER.CONTACT_PROJECT_MANAGER.LOOKING_FOR_HELP_WITH_TRANSLATION_CHAT_MESSAGE");
    trackEventChat("CONTACTMANAGER");
    showNewMessageChat(template);
  };

  onSendWoopra = () => {
    woopra.track('Clicked the "contact project manage" and "place an order here" links', {
      env: process.env.SERVER_NAME,
    });
  };

  hendleCheckboxPopupClose = () => {
    this.setState({
      popupCheckbox: false,
      googleSheetsData: null,
    });
  };

  handlePopupAccept = () => {
    const { popupMessages, isAddFilesPopup } = this.state;
    const { sourceLangErrors, hintErrors } = popupMessages;

    if (sourceLangErrors) {
      this.setState(
        {
          popupMessages: { ...popupMessages, sourceLangErrors: false },
          dialogOpen: false,
        },
        () => this.checkCommentariesWarnings()
      );
    } else if (hintErrors) {
      this.setState(
        {
          popupMessages: { ...popupMessages, hintErrors: false },
          dialogOpen: false,
        },
        () => this.createOrder()
      );
    } else if (isAddFilesPopup) {
      this.setState({
        dialogOpen: false,
        isAddFilesPopup: false,
        textClose: I18n.t("CUSTOMER_APP.ORDERS_PAGE.QUEUE_SECTION.BUTTON_CLOSE"),
      });
    }
  };

  saveSearchSourceLanguageInputValue = text => {
    const { changeTableState } = this.props;

    changeTableState({
      searchSourceLanguageInputValue: text,
    });
  };

  onFocusSourceTextArea = () => {
    const { sourceText } = this.state;
    const { tableState } = this.props;

    const { sourceTextCounter, sourceText: storedSourceText } = tableState;

    const splittedSourceText = sourceText.split("\n");
    const amountOfChars = this.calculateSourceTextChars(splittedSourceText);
    const isTextChanged = sourceText !== storedSourceText || sourceTextCounter !== amountOfChars;

    if (!isTextChanged) return;
    this.saveSourceTextToStore(sourceText, amountOfChars);

    this.state.sourceTitleRef &&
      this.state.sourceTitleRef.setCounter &&
      this.state.sourceTitleRef.setCounter(amountOfChars);

    this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(amountOfChars) });
  };

  refreshBalance = () => {};

  onCloseAddFundsBlock = () => {
    this.setState({
      isShowAddFundsBlock: false,
    });
  };

  onShowAddFundsBlock = () => {
    this.setState({
      isShowAddFundsBlock: true,
    });
  };

  handlePaymentMethodChange = e => {
    const paymentMethodName = e.value;
    let paymentMethodNameTmp = paymentMethodName === "CO2" ? "2_CHECKOUT" : paymentMethodName;
    let amountCurrency = "RUB";
    let equivalentDisplay = false;

    let couponDescriptionDisplay = false;
    let couponLabel = I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.AMOUNT");
    let buttonLabel = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADD_FUNDS");

    if (paymentMethodName === "COUPON") {
      couponLabel = I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.REDEEM_CODE");
      buttonLabel = I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.BUTTON_LABEL_REDEEM_CODE");

      couponDescriptionDisplay = true;
    }

    if (
      paymentMethodName === "WIRE_USD" ||
      paymentMethodName === "WIRE_RUB" ||
      paymentMethodName === "WIRE_BYN" ||
      paymentMethodName === "WIRE_YEN"
    ) {
      buttonLabel = I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.BUTTON_LABEL_ISSUE_INVOICE");
      if (paymentMethodName === "WIRE_BYN") {
        this.props.getExchangeRate("BYN");
        amountCurrency = "BYN";
        equivalentDisplay = true;
      }
      if (paymentMethodName === "WIRE_RUB") {
        this.props.getExchangeRate("RUB");
        amountCurrency = "RUB";
        equivalentDisplay = true;
      }
      if (paymentMethodName === "WIRE_USD") {
        this.props.getExchangeRate("RUB");
        amountCurrency = "";
        equivalentDisplay = false;
      }
      if (paymentMethodName === "WIRE_YEN") {
        this.props.getExchangeRate("JPY");
        amountCurrency = "JPY";
        equivalentDisplay = false;
      }
    }

    if (paymentMethodName === "CRYPTOCURRENCY_USDT_USDC") {
      buttonLabel = I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.BUTTON_LABEL_CRYPTO");
      paymentMethodNameTmp = "CRYPTO_USDTC";
    }

    this.setState({
      couponLabel,
      buttonLabel,
      equivalentDisplay,
      amountCurrency,
      couponDescriptionDisplay,
      paymentMethodValue: {
        value: paymentMethodName,
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS." + paymentMethodNameTmp),
      },
    });
  };

  handleAmountChange = e => {
    this.setState({
      amount: e.target.value,
    });
  };

  handleChangeAdvancedSettings = e => {
    this.props.changeTableState({
      advancedSettingsValue: e.value,
    });
    this.setState({ wasAdvancedSettingsSelectorsChanged: true });
  };
  handleChangeCategorySettings = e => {
    this.props.changeTableState({
      advancedCategoryValue: OrderTranslationCategory[e.value],
    });
    this.setState({ wasAdvancedSettingsSelectorsChanged: true });
  };
  showInputDiscount = () => {
    this.setState({
      isShowDiscountInput: true,
    });
  };

  applyDiscount = code => {
    this.props.setDiscountInfo(code).then(discount => {
      let discountValidationMessage = "";

      if (!discount.status || discount.status === "redeemed" || discount.status === "blocked") {
        discountValidationMessage = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT.VALIDATE_MESSAGE.INVALID_CODE");
      } else if (discount.status === "expired") {
        discountValidationMessage = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT.VALIDATE_MESSAGE.EXPIRE_CODE");
      }

      if (discountValidationMessage) {
        this.setState({
          discountValidationMessage: discountValidationMessage,
          isShowDiscountInput: true,
          discountInputValue: code,
        });
      } else {
        this.props.changeTableState({
          discount: { ...discount, code: code },
        });
        woopra.track("Add discount code", {
          discount: code,
          env: process.env.SERVER_NAME,
        });
        this.setState({
          isShowDiscountInput: false,
          discountValidationMessage: "",
          discountInputValue: "",
        });
      }
    });
  };

  removeDiscount = () => {
    this.props.changeTableState({
      discount: "",
    });
    this.setState({
      isShowDiscountInput: false,
      discountValidationMessage: "",
      discountInputValue: "",
    });
    setCookie("discount", "");
  };

  renderDiscount = () => {
    const { isShowDiscountInput, discountValidationMessage, discountInputValue, filesTotalPrice } = this.state;
    const { discount } = this.props.tableState;

    const discountMessage = translatedTextWithLink(I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT.MESSAGE"));
    const discountSummFormatted = this.formatCurrencyForTablePrice(
      filesTotalPrice ? filesTotalPrice?.discountSumm : { usd: 0 }
    );
    const priceWithDiscount = filesTotalPrice?.priceWithDiscount
      ? this.formatCurrencyForTablePrice(filesTotalPrice?.priceWithDiscount)
      : "";

    return (
      <div
        style={{
          marginTop: 8,
          display: "flex",
          alignItems: "center",
          lineHeight: "18px",
          justifyContent: "flex-end",
        }}
      >
        {isShowDiscountInput ? (
          <Discount
            placeholder={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT.INPUT_PLACEHOLDER")}
            buttonText={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT.BUTTON")}
            applyDiscount={this.applyDiscount}
            validationMessage={discountValidationMessage}
            inputValue={discountInputValue}
          />
        ) : _.isEmpty(discount) ? (
          <span style={{ fontSize: 13 }}>
            {discountMessage.leftText}
            <span>
              <LinkWrapper
                style={{ fontSize: 13 }}
                textContent={discountMessage.link}
                onClick={this.showInputDiscount}
              />
            </span>
            {discountMessage.rightText}
          </span>
        ) : (
          <div styleName={"form.discountResultContainer"} style={{ flexDirection: "column", alignItems: "flex-end" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div styleName={"form.discountResult"} style={{ color: "#212121", display: "flex" }}>
                <HtmlTranslate
                  innerStyleName={"translate.discountJPY"}
                  code={discount.code}
                  amount={discount.percent}
                  value="CUSTOMER_APP.NEW_ORDER_PAGE.DISCOUNT.DISCOUNT_INFO"
                />
                <div style={{ marginLeft: 4 }}>{discountSummFormatted}</div>
              </div>
              <IcomoonReact
                iconSet={iconSet}
                color={"#616161"}
                size={10}
                icon={"close"}
                style={{ marginLeft: 10, marginRight: 0 }}
                styleName={"form.discountRemove"}
                onClick={this.removeDiscount}
              />
            </div>
            <div style={{ marginTop: 4, fontWeight: "bold", fontSize: 14 }}>{priceWithDiscount}</div>
          </div>
        )}
      </div>
    );
  };

  handlerAddFiles = () => {
    this.setState({
      isUploadFilePopoverOpen: !this.state.isUploadFilePopoverOpen,
    });
  };

  sendUploadFileWoopra(format) {
    const data = {
      event: "Upload file",
      format,
      env: process.env.SERVER_NAME,
    };
    woopra.track("Upload file", data);
  }

  handleBlurPopover = () => {
    this.setState({
      isUploadFilePopoverOpen: false,
    });
  };

  handlerUploadGoogleDriveOpenPopup = e => {
    this.handleBlurPopover(e);
    this.setState({
      uploadGoogleDrivePopupShow: true,
    });
  };
  handlerUploadGoogleSheetsOpenPopup = e => {
    this.handleBlurPopover(e);
    this.setState({
      uploadGoogleSheetsPopupShow: true,
    });
  };
  onChangeGoogleDrive = e => {
    this.setState({
      googleDriveUrl: e.target.value,
    });
  };
  handleEditGoogleSheetsFile = e => {
    e.stopPropagation();
    this.setState({
      popupCheckbox: true,
      googleSheetsData: this.props.tableState.sheetsData,
    });
  };

  handleUploadGoogleFile = (googleSheetsLink, sheetsData, googleDriveUrl) => {
    const { uploadGoogleSheetsTable, changeTableState } = this.props;
    const closeIcon = (
      <div styleName={"row.deleteFileIcon"}>
        <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
      </div>
    );
    this.setState({ loadingGoogleSheetTable: true });
    uploadGoogleSheetsTable(googleSheetsLink, sheetsData, this.state.files && this.state.files.uuid)
      .then(file => {
        console.log(file);

        const newFiles = [...this.state.files];
        const newFile = {
          ...JSON.parse(file),
          link: googleDriveUrl,
          deleteIcon: closeIcon,
        };
        newFiles[0] = newFile;
        this.setState(
          {
            files: newFiles,
            googleDriveUrl: "",
            googleSheetsUrlStatus: "",
          },
          () => {
            changeTableState({
              files: newFiles,
              orderType: "googleFile",
            });
            this.handleSourceLanguageDetected();
            this.state.sourceTitleRef.setCounter && this.state.sourceTitleRef.setCounter(newFiles[0].volume);
            this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(this.state.files[0].volume) });
          }
        );

        this.hendleCheckboxPopupClose();
        this.setState({ loadingGoogleSheetTable: false });
        this.props.showNotification(I18n.t("UPLOAD_FILES_POPUP.UPLOAD_SUCCESS"));
      })
      .catch(() => {
        this.setState({
          googleSheetsUrlStatus: "noAccessLink",
          loadingGoogleSheetTable: false,
        });
      });
  };

  abortRequest = requestName => {
    const controller = this.state.abortController[requestName];
    if (!controller) return;

    controller.abort();
  };

  setAbortController = (requestName, controller) => {
    this.setState(prev => {
      return {
        ...prev,
        abortController: {
          ...prev.abortController,
          [requestName]: controller ? controller : undefined,
        },
      };
    });
  };

  setTimerForSpreadsheet = () => {
    this.longSpreadsheetLoadingTimer = setTimeout(() => {
      this.setState({ isLongSpreadsheetLoading: true });
    }, 10000);
  };

  handleUploadGoogleDrivePopupAccept = () => {
    const { uploadGoogleFile, changeTableState, uploadGoogleSheets } = this.props;
    const { googleDriveUrl, sourceTitleRef, sidebarInfoCounterRef, uploadGoogleSheetsPopupShow } = this.state;
    const closeIcon = (
      <div styleName={"row.deleteFileIcon"}>
        <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
      </div>
    );
    if (this.state.loadingGoogleSource) {
      return;
    }
    const regexp = new RegExp(/^(?:docs\.google\.com|https?):\/\/[A-Za-z0-9]/);
    const docsValidation = new RegExp(/(docs|drive).google.com\/(forms|presentation|drive|file)\//);
    const checkUrl = regexp.test(googleDriveUrl);
    const checkDocsValidation = docsValidation.test(googleDriveUrl);
    if (checkUrl && googleDriveUrl && googleDriveUrl.indexOf("https://docs.google.com/document") >= 0) {
      //todo переделать взятие айдики из документа или отдать на сервер урл и там брать айдишку

      const document = nodeUrl.parse(googleDriveUrl, true, true).path;
      const documentId = document.split("/")[3];
      this.setState({ loadingGoogleSource: true });
      uploadGoogleFile(documentId)
        .then(file => {
          if (file.error === undefined) {
            console.log("uploadGoogleFile file", file);
            const newFile = {
              ...JSON.parse(file),
              link: googleDriveUrl,
              deleteIcon: closeIcon,
            };

            const newFiles = [newFile];

            this.setState(
              {
                files: newFiles,
                googleDriveUrl: "",
                googleDriveUrlStatus: "",
              },
              () => {
                changeTableState({
                  files: newFiles,
                  orderType: "googleFile",
                });
                this.saveDraft();
                this.handleSourceLanguageDetected();
                sourceTitleRef.setCounter && sourceTitleRef.setCounter(newFiles[0].volume);
                sidebarInfoCounterRef.setSidebarInfo &&
                  sidebarInfoCounterRef.setSidebarInfo(
                    I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.AMOUNT_CHARS_ON_TEXT", {
                      amount: this.state.files[0].volume,
                    })
                  );
              }
            );
            this.handleUploadGoogleDrivePopupClose();
            this.sendUploadFileWoopra("Google Docs");

            this.props.showNotification(I18n.t("UPLOAD_FILES_POPUP.UPLOAD_SUCCESS"));
          } else if (file.error.status === 412 || file.error.status === 403) {
            file.error.text.then(res => {
              const result = JSON.parse(res);

              if (result === "Unsupported file format") {
                this.setState({
                  googleSheetsUrlStatus: "unsupportedSheetsFormat",
                  googleDriveUrlStatus: "unsupportedFileFormat",
                  typeGoogleFile: "document",
                });
              } else if (result === "The file is not accessible") {
                this.setState({
                  googleDriveUrlStatus: "noAccessLink",
                  googleSheetsUrlStatus: "noAccessLink",
                });
              } else if (result === "file size") {
                this.setState({
                  googleDriveUrlStatus: "nullFileSize",
                  googleSheetsUrlStatus: "nullFileSize",
                });
              }

              //todo: get error in this block and optimize into one .catch solution
              const firstError = result?.length && result[0];
              if (!firstError) return;

              const status = GOOGLE_ERROR_STATUSES[firstError?.message] || {};

              this.setState(prev => {
                return {
                  ...prev,
                  ...status,
                  typeGoogleFile: firstError?.message === "Unsupported file format" ? "document" : prev.typeGoogleFile,
                };
              });
            });
          }
          this.setState({ loadingGoogleSource: false });
        })
        .catch(e => {
          this.setState({ loadingGoogleSource: false });
          e?.error?.text?.then(res => {
            const result = JSON.parse(res);
            const firstError = result?.length && result[0];
            if (!firstError) return;

            const status = GOOGLE_ERROR_STATUSES[firstError.message] || {};

            this.setState(prev => {
              return {
                ...prev,
                ...status,
                typeGoogleFile: firstError?.message === "Unsupported file format" ? "document" : prev.typeGoogleFile,
              };
            });
          });
        });
    } else if (checkUrl && googleDriveUrl && googleDriveUrl.indexOf("https://docs.google.com/spreadsheets") >= 0) {
      const document = nodeUrl.parse(googleDriveUrl, true, true).path;
      const documentId = document.split("/")[3];
      this.setState({ loadingGoogleSource: true });
      this.setTimerForSpreadsheet();
      uploadGoogleSheets(documentId, controller => this.setAbortController(SPREADSHEETS_SERVICE_NAME, controller))
        .then(data => {
          if (data?.isError) throw data.value;
          data = data.value;
          if (data.error === undefined) {
            this.setState({
              popupCheckbox: true,
              googleSheetsData: JSON.parse(data),
              googleSheetsDataFileName: JSON.parse(data).title,
              googleSheetsLink: documentId,
              googleDriveUrl: googleDriveUrl,
            });
            this.sendUploadFileWoopra("Google Sheets");
            this.handleUploadGoogleDrivePopupClose();
          } else if (data?.error?.status === 412 || data.error.status === 403) {
            data.error.text.then(res => {
              const result = JSON.parse(res);
              if (result === "Unsupported file format") {
                this.setState({
                  googleSheetsUrlStatus: "unsupportedSheetsFormat",
                  googleDriveUrlStatus: "unsupportedFileFormat",
                  typeGoogleFile: "spreadsheets",
                });
              } else if (result === "The file is not accessible") {
                this.setState({
                  googleDriveUrlStatus: "noAccessLink",
                  googleSheetsUrlStatus: "noAccessLink",
                });
              } else if (result === "file size") {
                this.setState({
                  googleDriveUrlStatus: "nullFileSize",
                  googleSheetsUrlStatus: "nullFileSize",
                });
              }
            });
          }
        })
        .catch(e => {
          const isAbortedRequest = e?.code === 20;
          if (isAbortedRequest) return;

          const result = JSON.parse(e);
          const firstError = result?.length && result[0];
          if (!firstError) return;
          const status = GOOGLE_ERROR_STATUSES[firstError.message] || {};
          this.setState(prev => {
            return {
              ...prev,
              ...status,
              typeGoogleFile: firstError?.message === "Unsupported file format" ? "spreadsheets" : prev.typeGoogleFile,
            };
          });
        })
        .finally(() => {
          this.setState({
            isLongSpreadsheetLoading: false,
            loadingGoogleSource: false,
          });
          clearTimeout(this.longSpreadsheetLoadingTimer);
        });
    } else if (uploadGoogleSheetsPopupShow && checkDocsValidation) {
      this.setState({
        googleSheetsUrlStatus: "unsupportedSheetsFormat",
      });
    } else if (checkDocsValidation) {
      this.setState({
        googleDriveUrlStatus: "unsupportedFileFormat",
      });
    } else {
      this.setState({
        googleDriveUrlStatus: "invalidLink",
        googleSheetsUrlStatus: "invalidLink",
      });
    }
  };

  handleUploadGoogleDrivePopupClose = () => {
    this.setState({
      uploadGoogleDrivePopupShow: false,
      uploadGoogleSheetsPopupShow: false,
      googleDriveUrl: "",
      googleDriveUrlStatus: "",
      googleSheetsUrlStatus: "",
      typeGoogleFile: "",
      isLongSpreadsheetLoading: false,
      loadingGoogleSource: false,
    });
    this.abortRequest(SPREADSHEETS_SERVICE_NAME);
    this.setAbortController(SPREADSHEETS_SERVICE_NAME);
    clearTimeout(this.longSpreadsheetLoadingTimer);
  };

  deleteFile = file => {
    console.log("deleteFile", file);
    this.props.changeTableState({
      files: [],
      discountsTMList: [],
      summaryDiscountTM: 0,
      orderType: "text",
      uploadedFileSegmentsData: null,
      sourceText: "",
      sourceTextCounter: 0,
      filesTotalPrice: null,
      sheetsData: null,
    });

    this.setState(
      {
        sourceText: "",
        files: [],
        filesTotalPrice: {
          discountSumm: 0,
        },
        filePreviewPopup: false,
        uploadFileElements: [],
        uploadFileParserInfo: {},
        showBtnCalculate: true,
        needApplyTmPmDiscount: false,
        sheetsData: null,
      },
      () => {
        this.saveDraft();
        this.sourceLanguageDetectedFromFile();
        this.state.sourceTitleRef.setCounter && this.state.sourceTitleRef.setCounter(0);
        this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(0) });
        // this.props.deleteGoogleFile(file.uuid);
      }
    );
  };

  sendForPriceCalculation = data => {
    const { ws } = this.props;
    ws?.send(data);
  };

  getSourceTextSidebarInfoText = (amount = 0) => {
    return I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.AMOUNT_CHARS_ON_TEXT", {
      amount: amount,
    });
  };

  setSourceSectionAndSidebarCharsAmount = (amount = 0) => {
    this.state.sourceTitleRef.setCounter && this.state.sourceTitleRef.setCounter(amount);
    this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(amount) });
  };

  getTargetLanguagesDataForPriceCalculations = () => {
    const { tableState } = this.props;
    const { selectedTargetLanguage, discountsTMList } = tableState;

    return Object.keys(selectedTargetLanguage).map(key => {
      const tmpm = discountsTMList?.find(tmpm => tmpm.language === key);

      return {
        targetLanguage: key,
        discountTm: tmpm ? tmpm.percent * 100 : undefined,
        tmVolume: tmpm ? tmpm.volume : undefined,
        pmVolume: tmpm ? tmpm.pmVolume : undefined,
      };
    });
  };

  getPayloadForPriceCalculations = volume => {
    const { selectedService } = this.state;
    const { discount, sourceTextCounter, sourceLanguage } = this.props.tableState;
    const targetLanguagesData = this.getTargetLanguagesDataForPriceCalculations();

    return {
      order_type: ORDER_TYPES_BY_SELECTED_SERVICE[selectedService],
      sourceLanguage: sourceLanguage.value,
      discountPercent: discount?.percent || 0,
      targetLanguages: targetLanguagesData,
      volume: volume || sourceTextCounter,
    };
  };

  calculateOrderPrice = volume => {
    const payloadForPriceCalculation = this.getPayloadForPriceCalculations(volume);
    this.sendForPriceCalculation(payloadForPriceCalculation);
  };

  calculatePrice = () => {
    if (this.state.files && this.state.files.length > 0) {
      const volume = this.state.files[0].volume;
      this.calculateOrderPrice(volume);
      return;
    }
    this.calculateOrderPrice();
  };

  formatCurrencyForTablePrice = ({ usd }) => {
    const priceUsd = formatNumAsCurrency({
      value: usd,
      currency: USD,
      showIsZero: true,
    });

    return priceUsd;
  };

  handleTranslationLengthChange = value => {
    const { changeTableState } = this.props;
    changeTableState({
      maxTranslationsLength: value,
    });
  };
  handleOpenUploadFilePopup = e => {
    this.setState({ uploadFilePopup: true });
  };
  handleCloseUploadFilePopup = () => {
    const { files } = this.state;
    const { tableState } = this.props;
    this.setState({ uploadFilePopup: false });
    if (tableState.uploadedFileSegmentsData) return;
    this.deleteFile(files[0]);
  };

  handleOpenCSVSettingsPopup = e => {
    this.setState({ uploadCSVSettingsPopup: true });
  };

  setUploadFileElements = elements => {
    this.setState({ uploadFileElements: elements });
  };

  setParserInfo = info => {
    this.setState({ uploadFileParserInfo: info });
  };

  handleCloseCSVSettingsPopup = (shouldRemoveFile = false) => {
    const { files } = this.state;
    this.setState({ uploadCSVSettingsPopup: false });

    if (shouldRemoveFile) {
      this.deleteFile(files[0]);
      this.setParserInfo({});
    }
  };

  handleOpenCSVSelectorPopup = () => {
    this.setState({ uploadCSVSelectorPopup: true });
  };

  handleCloseCSVSelectorPopup = (shouldRemoveFile = false) => {
    const { files } = this.state;
    this.setState({ uploadCSVSelectorPopup: false, isUploadCSVLoading: false });

    if (shouldRemoveFile) {
      this.deleteFile(files[0]);
      this.setParserInfo({});
    }
  };

  setErrorMessageForFile = ({ mainText, fileName }) => {
    const closeIcon = (
      <div styleName={"row.deleteFileIcon"}>
        <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
      </div>
    );
    let text = unicodeToString(mainText);
    text = formatString(text);
    const newFiles = [
      {
        isError: true,
        filename: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#ef5350",
            }}
          >
            <div style={{ width: 16, height: 16 }}>
              <div style={{ width: 16, height: 16, position: "absolute" }}>
                <Question question={text} iconColor="iconRed" iconType="info" initialOpen={true} />
              </div>
            </div>
            <div style={{ marginLeft: 6 }}>{fileName}</div>
          </div>
        ),
        deleteIcon: closeIcon,
      },
    ];
    this.setState({
      files: newFiles,
      isUploadFilePopoverOpen: false,
      uploadCSVSettingsPopup: false,
      uploadCSVSelectorPopup: false,
    });
  };

  openCSVPopups = () => {
    if (this.state.uploadCSVSettingsPopup) {
      this.handleCloseCSVSettingsPopup();
      this.handleOpenCSVSelectorPopup();
      return;
    }

    this.handleOpenCSVSettingsPopup();
  };

  handleCSVSettingsResponseSuccess = (formattedData, fileText) => {
    const parsedParserInfo = JSON.parse(formattedData.parser_info);
    const parsedFileElements = JSON.parse(formattedData.elements);
    const isErrorWithDefaultParserInfo = parsedFileElements === null && !parsedParserInfo?.delimiter;
    this.setState({
      uploadFileParserInfo: parsedParserInfo,
      uploadFileElements: parsedFileElements,
      uploadFileDefaultParserInfoError: isErrorWithDefaultParserInfo,
      files: [{ ...this.state.files[0], filesAsFormattedText: fileText }],
    });

    this.openCSVPopups();
  };

  getStringsErrorText = err => {
    const { message, parameters } = err;

    switch (message) {
      case "Duplicate keys":
        return I18n.t("FILE_STRINGS.VALIDATION.DUPLICATE_KEY", { string: parameters[0], key: parameters[1] });
      case "Invalid syntax":
        return I18n.t("FILE_STRINGS.VALIDATION.INVALID_SYNTAX", { string: parameters[0], param: parameters[1] });
      default:
        return "default";
    }
  };

  getJSONErrorText = err => {
    const { message, parameters } = err;

    switch (message) {
      case "Not a JSON Object.":
        return I18n.t("FILE_JSON.VALIDATION.NOT_A_JSON");
      case "The file has no text to translate":
        return I18n.t("FILE_JSON.VALIDATION.EMPTY_FILE");
      case "Unterminated object":
        return I18n.t("FILE_JSON.VALIDATION.UNTERMINATED_OBJECT", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      case "Expected ':'":
        return I18n.t("FILE_JSON.VALIDATION.EXPECTED_COLON", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      case "End of":
        return I18n.t("FILE_JSON.VALIDATION.END_OF_INPUT", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      case "Expected name":
        return I18n.t("FILE_JSON.VALIDATION.EXPECTED_NAME", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      case "Unterminated array":
        return I18n.t("FILE_JSON.VALIDATION.UNTERMINATED_ARRAY", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      case "Unexpected value":
        return I18n.t("FILE_JSON.VALIDATION.UNEXPECTED_VALUE", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      case "Duplicate key":
        return I18n.t("FILE_JSON.VALIDATION.DUPLICATE_KEY", { line: parameters[0], column: parameters[1] });
      case "Expected value":
        return I18n.t("FILE_JSON.VALIDATION.EXPECTED_VALUE", {
          line: parameters[0],
          column: parameters[1],
          path: parameters[3],
        });
      default:
        return I18n.t("FILE_JSON.VALIDATION.DEFAULT");
    }
  };

  getSRTErrorText = err => {
    const { message, parameters } = err;

    switch (message) {
      case "There is a missing tag in the file":
        return I18n.t("FILE_SRT.VALIDATION.MISSING_TAG_SRT", { tag: parameters[0] });
      case "There is no numeric counter ":
        return I18n.t("FILE_SRT.VALIDATION.NO_NUMERIC_COUNTER_SRT", { counter: parameters[0] });
      case "There is no timecode in string":
        return I18n.t("FILE_SRT.VALIDATION.NO_TIMECODE_SRT", { timecode: parameters[0] });
      default:
        return I18n.t("FILE_SRT.VALIDATION.FAILED_LOAD_SRT");
    }
  };

  getErrorAfterFailedFileUpload = (error = "", orderType) => {
    const { files } = this.state;
    // const COMMON_ERRORS_FIELDS =  ["content", "file size"];
    // common - displays as always (red color for filename + popover with text); fields: ["content", "file size"]
    // custom - returns field into a place, where you call upload func and extract errText there
    const err = { common: "", custom: {} };

    if (!isJsonString(error)) {
      err.common = I18n.t("FILE_STRINGS.VALIDATION.FAILED_LOAD", { fileName: files[0].filename });
      return err;
    }

    const parsedErrors = JSON.parse(error);

    if (Array.isArray(parsedErrors)) {
      const errors = parsedErrors.reduce((acc, e) => {
        const field = e?.field;
        if (!field) return acc;

        let errorMessage = e.message;

        if (orderType === ORDER_FORMATS.SRT) errorMessage = this.getSRTErrorText(e);
        if (orderType === ORDER_FORMATS.JSON) errorMessage = this.getJSONErrorText(e);
        if (orderType === ORDER_FORMATS.STRINGS) errorMessage = this.getStringsErrorText(e);

        if (field === "file size" && !["json", "strings"].includes(orderType)) {
          errorMessage = I18n.t("UPLOAD_FILES_POPUP.ERROR_MESSAGE_STRING_FILE_SIZE");
        }

        err.custom[field] = e?.message;
        return [...acc, errorMessage];
      }, []);

      err.common = errors.join("\n");
    }

    // for custom error structure(not an array) -> ask error structure like: {type: "", reason: "", ...other}
    const isCSVWithReason =
      parsedErrors && parsedErrors.type && parsedErrors.type.toLowerCase() === ORDER_FORMATS.CSV && parsedErrors.reason;
    const isFileUploaded = files?.length && files[0]?.filesAsFormattedText;

    if (isCSVWithReason && isFileUploaded) {
      err.custom.missingChar = parsedErrors;
    }

    if (isCSVWithReason && !isFileUploaded) {
      err.common = I18n.t("FILE_STRINGS.VALIDATION.FAILED_LOAD", { fileName: files[0]?.filename || "" });
    }

    return err;
  };

  uploadFileFromPopup = ({ fileName, fileText, parserInfo }) => {
    const { files } = this.state;
    const { createOrderPopup } = this.props;

    const payload = {
      content: fileText,
      filename: files[0].filename,
      type: files[0].type,
      parser_info: parserInfo,
    };

    return createOrderPopup(payload)
      .then(response => {
        if (response?.isError) throw response.value;
        const formattedData = JSON.parse(response.value);

        if (files[0].type.toLowerCase() === ORDER_FORMATS.CSV) {
          this.handleCSVSettingsResponseSuccess(formattedData, fileText);
        }

        if (files[0].type.toLowerCase() === ORDER_FORMATS.XML) {
          this.setState({
            uploadFileElements: JSON.parse(formattedData.elements),
            files: [{ ...this.state.files[0], filesAsFormattedText: fileText }],
          });
          this.handleOpenUploadFilePopup();
        }
      })
      .catch(e => {
        const isAborted = e?.code === 20;
        if (isAborted) return;

        const error = this.getErrorAfterFailedFileUpload(e);

        if (error?.common) {
          this.setErrorMessageForFile({ mainText: error.common, fileName: fileName || files[0].filename });
        }

        return { error: error?.custom };
      });
  };
  uploadFile = ({ fileName, text, fileType, selected, ...props }) => {
    const closeIcon = (
      <div styleName={"row.deleteFileIcon"}>
        <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
      </div>
    );
    const { changeTableState, uploadFileFromComputer } = this.props;
    const fileTypeLowerCased = fileType.toLowerCase();
    if (selected && fileTypeLowerCased !== ORDER_FORMATS.CSV) {
      cursorLocalWait(true);
    }

    this.setState({ isUploadCSVLoading: true });
    uploadFileFromComputer({
      name: fileName,
      content: text,
      type: fileType.toUpperCase(),
      selected: selected,
      ...props,
    })
      .then(response => {
        const woopraType = fileTypeLowerCased;
        this.sendUploadFileWoopra(woopraType === "strings" ? `.${woopraType}` : woopraType);
        cursorLocalWait(false);
        const newFiles = [
          {
            ...JSON.parse(response),
            deleteIcon: closeIcon,
            filesAsFormattedText: text,
          },
        ];
        this.setState(
          {
            files: newFiles,
            uploadFilePopup: false,
          },
          () => {
            changeTableState({
              files: newFiles,
            });
            if (fileType === ORDER_FORMATS.TXT) this.handleOpenPreviewPopup(); //todo: fileType and fileTypeLowerCased
            if (fileTypeLowerCased === ORDER_FORMATS.CSV) this.handleCloseCSVSelectorPopup();

            this.handleSourceLanguageDetected();
            this.state.sourceTitleRef.setCounter && this.state.sourceTitleRef.setCounter(newFiles[0].volume);
            this.setState({ sourceTextSidebarInfo: this.getSourceTextSidebarInfoText(this.state.files[0].volume) });
          }
        );
        this.setState({ isUploadCSVLoading: false });
      })
      .catch(e => {
        e?.error?.text?.then(text => {
          this.setState({ isUploadCSVLoading: false });
          const error = this.getErrorAfterFailedFileUpload(text, fileType);

          if (error?.common) {
            this.setErrorMessageForFile({ mainText: error.common, fileName: fileName || files[0].filename });
          }

          // return { error };
        });
      });
  };
  onUploadFileFromComputer = ({ file, selected }) => {
    const { changeTableState } = this.props;
    const spinnerIcon = <HandySvg className="spinner-file-loading" width="16" height="16" src={spinner} />;
    const regExp = new RegExp(/^data.+base64,/g);
    const fileParts = file?.name.split(".");
    const fileFormat = fileParts[fileParts.length - 1].toLowerCase();
    const fileFormatUpperCased = fileFormat.toUpperCase();
    const ERROR_MESSAGE = {
      fileType: I18n.t("FILE_STRINGS.VALIDATION.NOT_STRING"),
      fileSize: I18n.t("FILE_STRINGS.VALIDATION.MAX_SIZE", { fileName: file.name, maxSize: 30 }),
    };
    if (!ORDER_FORMATS[fileFormat.toUpperCase()]) {
      return this.setErrorMessageForFile({ mainText: ERROR_MESSAGE.fileType, fileName: file?.name });
    }
    if (file.size > limitStringsSize) {
      return this.setErrorMessageForFile({ mainText: ERROR_MESSAGE.fileSize, fileName: file?.name });
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileTemp = {
      file: file,
      filename: file.name,
      deleteIcon: spinnerIcon,
      type: fileFormatUpperCased,
    };
    this.setState({
      files: [fileTemp],
      isUploadFilePopoverOpen: false,
    });
    reader.onload = () => {
      const fileName = fileTemp.filename;
      const fileFormattedText = reader.result.replace(regExp, "");
      changeTableState({ orderType: "file" });
      if ((fileFormat === ORDER_FORMATS.XML && !selected) || fileFormat === ORDER_FORMATS.CSV) {
        this.uploadFileFromPopup({ fileName: fileName, fileText: fileFormattedText });
      } else {
        this.uploadFile({
          fileName: fileName,
          text: fileFormattedText,
          fileType: fileFormat,
          selected: selected,
        });
      }
    };
  };

  handleFormatClick = format => {
    woopra.track("Click to supported formats", {
      env: process.env.SERVER_NAME,
      format: format,
    });
  };
  onClearScrollItem = () => {
    this.setState({
      scrollToElementId: "",
    });
  };

  setFile = newFile => {
    const deleteIcon = (
      <div styleName={"row.deleteFileIcon"}>
        <IconView colors={["#616161", "#9E9E9E"]} size={10} icon={"close"} cursor={"pointer"} />
      </div>
    );

    this.setState(
      prev => {
        return {
          ...prev,
          files: [{ ...newFile, deleteIcon }],
        };
      },
      () => {
        this.props.changeTableState({
          files: [{ ...newFile, deleteIcon }],
        });
      }
    );
  };
  getUploadedOrderInfo = ({ format }) => {
    const { files } = this.state;
    const editOrderType = {
      CSV: {
        handleEdit: this.handleOpenCSVSettingsPopup,
        mainCharsCounter: 0,
        subCharsCounter: files[0].countSelectedColumns,
      },
      XML: {
        handleEdit: this.handleOpenUploadFilePopup,
        mainCharsCounter: files[0].countSelectedNodes,
        subCharsCounter: files[0].countSelectedAttributes,
      },
      GOOGLE_SPREADSHEET: {
        handleEdit: this.handleEditGoogleSheetsFile,
        mainCharsCounter: files[0].countSelectedLists,
        subCharsCounter: files[0].countSelectedColumns,
      },
    };
    return editOrderType[format];
  };

  handleBtnCalculateWithoutAuth = () => {
    const { files, filesTotalPrice } = this.state;
    const { me, tableState } = this.props;
    const { sourceText, sourceLanguage, selectedTargetLanguage } = tableState;
    const sourceTextPart = sourceText && (sourceText.length > 256 ? limit(sourceText, 256) : sourceText);

    this.setState({
      dialogSignInOpen: true,
    });
    woopra.track("Clicked calculate without auth", {
      id: me && me.id,
      email: me && me.email,
      source: sourceLanguage.value,
      target: Object.keys(selectedTargetLanguage),
      snippet: sourceTextPart,
      format: files[0] ? files[0].type : "Text",
      price: filesTotalPrice?.price?.usd,
      discount: filesTotalPrice?.discountSumm?.usd,
      sent: "",
      env: process.env.SERVER_NAME,
    });
  };

  setSourceLangToTarget = () => {
    const { changeTableState, tableState } = this.props;
    const { sourceLanguage, targetLanguages } = tableState;
    const sourceValue = sourceLanguage?.value;
    if (!sourceValue) return;

    const target = targetLanguages[sourceValue];
    if (!target.sourcePairs[sourceValue]) {
      changeTableState({
        sourceLanguage: { value: null },
      });
      return;
    }

    this.setState(prev => {
      return {
        ...prev,
        selectedTargetLanguage: { [target.id]: target },
        showBtnCalculate: true,
        needApplyTmPmDiscount: false,
        filesTotalPrice: null,
      };
    });
    changeTableState({
      selectedTargetLanguage: { [target.id]: target },
      discountsTMList: [],
    });
  };

  handleTranslationServiceChange = event => {
    const value = event.target.value;

    if (value === "AI") {
      this.checkAvailableSourceTargetPairs(null, value);
    }

    if (value === "PROOFREAD") {
      this.setSourceLangToTarget();
    }

    if (this.state.selectedService === "PROOFREAD") {
      this.resetSelectedTargetLanguages();
    }

    this.setState({
      selectedService: value,
    });
  };

  render() {
    const {
      validateErrors,
      sourceText,
      scrollToElementId,
      onFocusSource,
      sourceHint,
      placeholderMenu,
      focusDetectedBtn,
      languageBlockWidth,
      isLoadingMe,
      dialogOpen,
      dialogSignInOpen,
      dialogTitle,
      textAcceptCreateOrder,
      textAccept,
      isShowAddFundsBlock,
      paymentMethodValue,
      amount,
      couponLabel,
      buttonLabel,
      couponDescriptionDisplay,
      currencyAvailableWithSymbol,
      sidebarTop,
      isShowPopover,
      textClose,
      files,
      filesTotalPrice,
      popupCheckbox,
      googleSheetsData,
      googleSheetsDataFileName,
      googleSheetsLink,
      googleDriveUrl,
      filePreviewPopup,
      uploadFilePopup,
      uploadCSVSettingsPopup,
      uploadCSVSelectorPopup,
      uploadFileElements,
      uploadFileParserInfo,
      uploadFileDefaultParserInfoError,
      isUploadCSVLoading,
      serviceBlockOptions,
      sourceTextSidebarInfo,
      priceSidebarInfo,
      selectedService,
      langsWithProofread,
    } = this.state;
    const { coupon, changeTableState } = this.props;
    const disableIcon = !sourceText && _.isEmpty(files);

    const loginPopupTitle =
      this.props.router.location.pathname === "/signup"
        ? I18n.t("NEW_ORDER_PAGE.LOGIN_POPUP.SIGNUP_TITLE")
        : I18n.t("NEW_ORDER_PAGE.LOGIN_POPUP.SIGNIN_TITLE");

    //todo заменить redux tableState на локальный стейт или на локалсторадж или оставить редакс
    const {
      sourceTextCounter,
      scrollPosition,
      images,
      selectedTargetLanguage,
      targetLanguages,
      sourceLanguages,
      sourceLanguage,
      searchSourceLanguageInputValue,
      advancedSettingsValue,
      advancedCategoryValue,
      discount,
      discountsTMList,
      orderType,
    } = this.props.tableState;

    const isSourceLanguageValueNotExist = validateErrors.isSourceLanguageValueNotExist;
    const isSourceTextNotExist = validateErrors.isSourceTextNotExist;
    const sourceBlockStyle = { padding: "0px", border: "none" };
    const sourceBlockStyleRevert = {
      padding: "0px",
      border: "none",
      flexDirection: "column-reverse",
    };
    const textAreaSourceBlockStyle = {
      padding: "16px 32px 16px 16px",
      minRows: 11,
      margin: "1px",
      position: "relative",
      zIndex: 2,
      background: "transparent",
    };
    const textAreaStyle = {
      padding: "16px 32px 16px 16px",
      minRows: 6,
      margin: 1,
      marginBottom: 16,
    };
    const tabIndex = {
      sourceTextArea: 1,
      contextTextArea: 2,
      contextAddImage: 3,
    };
    const sourceContentStyle = { marginTop: "16px", paddingTop: 0 };
    const sourceTitleStyle = { marginBottom: "8px" };
    const hintContentStyle = {
      paddingTop: 0,
      minHeight: "139px",
      display: "flex",
      flexDirection: "column",
    };
    const sourceLanguageBlockStyle = { paddingTop: 0, marginTop: 0 };
    const mobile = window.innerWidth < 660;
    const targetLanguageStyle = {
      padding: "0",
      border: "none",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    };
    const tableBlockStyle = { marginBottom: "-20px" };
    const durationStyle = { padding: "0", border: "none" };
    const durationContentStyle = { padding: "0", paddingTop: "16px" };
    const priceStyle = { padding: "0", border: "none", minHeight: 0 };
    const addFunds = { padding: "0", border: "none" };
    const sourceTextCounterString = sourceTextCounter?.toString();
    const sortedTargetLanguages = this.getSortTargetLanguages(targetLanguages);
    const sortedSourceLanguages = this.getSortSourceLanguages(sourceLanguages);
    const isNoSelectedTargetLanguage = validateErrors.isNoSelectedTargetLanguage;
    const isNoContextError = validateErrors.isNoContextOrImages && validateErrors.isNoContextOrImages;
    const targetLanguagesError = isNoSelectedTargetLanguage ? isNoSelectedTargetLanguage : undefined;
    const notAvailableLanguagesMessage = translatedTextWithLink(
      I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.NOT_SUPPORTED_LANGUAGES_MESSAGE")
    );
    const amountSelectedTargetLanguages = Object.keys(selectedTargetLanguage).length
      ? Object.keys(selectedTargetLanguage).length
      : "0";
    const insufficientFundsError = validateErrors.insufficientFundsError;
    const isProofreadService = selectedService === "PROOFREAD";

    const paymentSelection = [
      {
        value: "STRIPE",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.STRIPE"),
      },
      {
        value: "CO2",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.2_CHECKOUT"),
      },
      {
        value: "PAYPRO",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.PAYPRO"),
      },
      {
        value: "CRYPTOCURRENCY_USDT_USDC",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.CRYPTO_USDTC"),
      },
      {
        value: "PAYPAL",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.PAYPAL"),
      },
      {
        value: "WIRE_USD",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.WIRE_USD"),
      },
      {
        value: "WIRE_YEN",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.WIRE_YEN"),
      },
      // {
      //   value: "WIRE_RUB",
      //   label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.WIRE_RUB"),
      // },
      // {
      //   value: "WIRE_BYN",
      //   label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.WIRE_BYN"),
      // },
      // {
      //   value: "PAYPRO_V_TO_V",
      //   label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.PAYPRO_V_TO_V"),
      // },
      {
        value: "COUPON",
        label: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_OPTIONS.COUPON"),
      },
    ];
    const filteredCategorySettingsList =
      this.props.i18n.locale === "ja"
        ? categorySettingsList.filter(category => category.value !== "ORDER_CATEGORY_BOOKMAKER_CASINO")
        : categorySettingsList;

    const splitAmount = roundToTwo(this.state.amount * this.props.exchangeRate)
      .toString()
      .split(".");
    const equivalent =
      this.state.amount && this.props.exchangeRate
        ? formatCurrency({
            value: { integer: splitAmount[0], decimal: splitAmount[1] },
            currency: this.state.amountCurrency,
            showIsZero: true,
          })
        : formatCurrency({
            value: { integer: 0, decimal: 0 },
            currency: this.state.amountCurrency,
            showIsZero: true,
          });

    const additionalSidebarBlock = (
      <Fragment>
        <div>
          <div ref={createOrderBtnRef => (this.createOrderBtnRef = createOrderBtnRef)}>
            {isShowPopover.id === "createOrder" && (
              <Tutorial
                style={{ bottom: 16, top: "auto" }}
                parentRef={this.createOrderBtnRef}
                content={isShowPopover}
              />
            )}
            <NitroButton
              buttonStyleName={"button.save-button"}
              tabIndex={20}
              style={{ height: 40, width: "100%", minWidth: 115 }}
              buttonIcon={
                <IcomoonReact
                  iconSet={iconSet}
                  color={"#FFFFFF"}
                  size={18}
                  style={{ marginTop: 3, marginLeft: 16 }}
                  icon={"just"}
                />
              }
              title={
                isProofreadService
                  ? I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.BUTTON_LABEL_PROOFREAD")
                  : I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.BUTTON_LABEL")
              }
              clickHandler={this.handleCreateOrder}
            />
          </div>
          <div style={{ paddingTop: 4 }}>
            <NitroButton
              buttonStyleName={""}
              classNames={classnames(button.saveBtn, button.finishLaterBtn)}
              tabIndex={20}
              style={{ height: 40, width: "100%", minWidth: 115 }}
              buttonIcon={
                <IcomoonReact iconSet={iconSet} color={"#43A047"} size={18} style={{ marginLeft: 16 }} icon={"save"} />
              }
              title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.BUTTON_FINISH_LATER")}
              clickHandler={() => this.setIsPopupFinishLaterOpened(true)}
              bsPrefix={"finishLater"}
            />
          </div>
        </div>
        <div
          styleName={"order.before-scroll-navigator-placement order.unauth"}
          ref={messageLink => (this.messageLink = messageLink)}
        >
          <HtmlTranslate
            value={"CUSTOMER_APP.NEW_ORDER_PAGE.HAVE_LARGE_TRANSLATION_CONTACT_PM_LINK"}
            id={"beforeContactManager"}
            idBlank={"beforeBlankContactManager"}
          />
        </div>
      </Fragment>
    );
    const targetToolbar = amountSelectedTargetLanguages !== "0" && (
      <Toolbar
        containerStyleName={"bar.target-language-toolbar-container"}
        text={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SELECTED_LANGUAGES", {
          amount: amountSelectedTargetLanguages,
        })}
        resetSelected={this.resetSelectedTargetLanguages}
        isSelect={amountSelectedTargetLanguages !== "0"}
      />
    );

    let supportBlocks = [
      {
        id: "service",
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.SERVICE"),
        sectionWrapper: true,
        sidebarInfo: I18n.t(`CUSTOMER_APP.NEW_ORDER_PAGE.SERVICE.SIDE_${this.state.selectedService}`),
        component: () => {
          return (
            <div>
              <BlockContent
                contentStyle={sourceLanguageBlockStyle}
                content={
                  <div>
                    <div className={form.selectService}>
                      <span>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SERVICE.DESCRIPTION")}</span>
                      <div style={{ paddingTop: 5 }}>
                        {serviceBlockOptions.map(({ value, label }) => {
                          const isAI = value === AIOption.value;

                          return (
                            <span style={{ display: "flex", flexDirection: "row" }}>
                              <label key={value} className={form.customRadio} htmlFor={label}>
                                <input
                                  id={label}
                                  type="radio"
                                  name="service"
                                  value={value}
                                  checked={this.state.selectedService === value}
                                  onChange={this.handleTranslationServiceChange}
                                />
                                <span styleName={"form.customRadioButtonSmall"}></span>
                                {I18n.t(label)}
                              </label>
                              {isAI && (
                                <span style={{ paddingLeft: 5, paddingTop: 3 }}>
                                  <Question question={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SERVICE.HINT")} />
                                </span>
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          );
        },
      },
      {
        id: "source",
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.SOURCE_TEXT"),
        getRefTitle: this.getRefTitle,
        getSidebarInfo: this.getSidebarInfoSource,
        counter: sourceTextCounterString,
        sidebarInfo: sourceTextSidebarInfo,
        isShowPopover: isShowPopover,
        component: () => (
          <div
            ref={sourceBlock => (this.sourceBlock = sourceBlock)}
            styleName={"form.sourceBlock"}
            style={isSourceLanguageValueNotExist ? sourceBlockStyleRevert : sourceBlockStyle}
          >
            <BlockContent
              errors={isSourceTextNotExist}
              content={
                files.length > 0 ? (
                  <TableBlock
                    columns={filesColumns({
                      onCellClick: this.deleteFile,
                      docsType: files[0].type,
                      onClickEditFile: this.getUploadedOrderInfo({ format: files[0].type })?.handleEdit,
                      mainCharsCounter: this.getUploadedOrderInfo({ format: files[0].type })?.mainCharsCounter,
                      subCharsCounter: this.getUploadedOrderInfo({ format: files[0].type })?.subCharsCounter,
                      charsCounter: files[0].volume,
                    })}
                    content={this.state.files}
                    width={"100%"}
                    notShowToolBar={true}
                    displayBody={true}
                    notBottomPadding={true}
                    isNotScrollWrapper={true}
                    hideTableHeader={true}
                    onClickFileTitle={this.handleOpenPreviewPopup}
                  />
                ) : (
                  <Fragment>
                    <SourceTextareaAutoresize
                      ref={sourceInput => (this.sourceInput = sourceInput)}
                      tabIndex={tabIndex.sourceTextArea}
                      autoFocus={false}
                      textAreaStyle={textAreaSourceBlockStyle}
                      isUploadFilePopoverOpen={this.state.isUploadFilePopoverOpen}
                      areaStyleName={"inputNewOrder"}
                      customPlaceholder={
                        <div styleName={"form.customPlaceholder"}>
                          <div styleName={"form.customPlaceholderText"}>
                            {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.PLACEHOLDER_SOURCE_TEXT_OR_FILE")}
                          </div>
                          <PopoverTest
                            placement={"bottom-start"}
                            isShowArrow={false}
                            isShowPopover={this.state.isUploadFilePopoverOpen}
                            popperStyleName={"addFiles"}
                            handleBlur={this.handleBlurPopover}
                            referenceElement={
                              <Button
                                tabIndex={1}
                                ref={upload => (this.upload = upload)}
                                styleName={"form.addFiles"}
                                onClick={this.handlerAddFiles}
                                style={{ position: "relative", zIndex: 3, marginBottom: 8 }}
                              >
                                {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.CHOOSE_FILES_BTN")}
                                <HandySvg
                                  styleName={"form.addFiles-triangle"}
                                  src={triangleIcon}
                                  width={7}
                                  height={5}
                                />
                              </Button>
                            }
                            popperElement={
                              <div styleName={"form.uploadButtonContainer"}>
                                <div
                                  styleName={"form.uploadButtonItem"}
                                  onClick={this.handlerUploadGoogleDriveOpenPopup}
                                >
                                  <HandySvg styleName={"form.googleDriveIcon"} src={googleDriveIcon} />
                                  <div styleName={"form.uploadButtonLabel"}>
                                    {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.UPLOAD_FILES_POPOVER.FROM_GOOGLE_DRIVE")}
                                  </div>
                                </div>
                                <div
                                  styleName={"form.uploadButtonItem"}
                                  onClick={this.handlerUploadGoogleSheetsOpenPopup}
                                >
                                  <HandySvg styleName={"form.googleDriveIcon"} src={googleDriveIcon} />
                                  <div styleName={"form.uploadButtonLabel"}>
                                    {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.UPLOAD_FILES_POPOVER.FROM_GOOGLE_SHEETS")}
                                  </div>
                                </div>
                                <div styleName={"form.uploadButtonItem"}>
                                  <label style={{ display: "flex", margin: 0 }}>
                                    <input
                                      type="file"
                                      styleName={"form.uploadButtonLabelInput"}
                                      onChange={event => this.onUploadFileFromComputer({ file: event.target.files[0] })}
                                      accept=".strings,application/json,text/html,text/plain,text/xml,text/csv, .srt"
                                      autoComplete="off"
                                      readOnly={true}
                                    />
                                    <HandySvg styleName={"form.computerIcon"} src={computerIcon} />
                                    <div styleName={"form.uploadButtonLabel"}>
                                      {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.UPLOAD_FILES_POPOVER.FROM_COMPUTER")}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            }
                          />
                        </div>
                      }
                      // value={sourceText}
                      initValue={this.props.tableState.sourceText}
                      onFocus={onFocusSource}
                      onFocusTextArea={this.onFocusSourceTextArea}
                      onChange={this.handleChangeSourceText}
                    />
                    <div styleName={"form.fileFormatContainer"}>
                      <span className={form.labelDescription} style={{ marginRight: 10 }}>
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.FORMAT_LABEL")}
                      </span>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_1"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("Google Docs")}
                      >
                        <HandySvg styleName={"form.fileFormatIcon"} src={docsIcon} />
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.GOOGLE_FORMAT")}
                        &nbsp;&nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_2"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("Google Sheets")}
                      >
                        <HandySvg styleName={"form.fileFormatIcon"} src={sheetsIcon} />
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.GOOGLE_SHEETS")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_3"}
                        target="_blank"
                        onClick={() => this.handleFormatClick(".strings")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.STRINGS_FORMAT")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_4"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("json")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.JSON_FORMAT")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_5"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("html")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.HTML_FORMAT")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_6"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("txt")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.TXT_FORMAT")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"support#supportedFormats_7"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("xml")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.XML_FORMAT")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_8"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("csv")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.CSV_FORMAT")}
                        &nbsp;
                      </a>
                      <a
                        styleName={"form.fileFormatLabel"}
                        href={"../new/support#supportedFormats_9"}
                        target="_blank"
                        onClick={() => this.handleFormatClick("srt")}
                      >
                        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.SRT_FORMAT")}
                      </a>
                    </div>
                    <div styleName={"form.fileFormatContainer"} style={{ marginTop: 12 }}>
                      <div styleName={"form.fileFormatLabel"}>
                        <HtmlTranslate value={"CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.ANOTHER_FILE_FORMAT"} />
                      </div>
                    </div>
                  </Fragment>
                )
              }
              contentStyle={sourceContentStyle}
              titleStyle={sourceTitleStyle}
              isReverseBlocks={true}
            />
          </div>
        ),
        sectionWrapper: true,
      },
      {
        id: "selectSourceLang",
        multiSection: true,
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SOURCE_LANGUAGE"),
        containerStyleName: "settingSection.containerLanguages",
        itemsStyleNames: ["settingSection.itemLanguages", "settingSection.nextItemLanguages"],
        isShowPopover: isShowPopover,
        component: () => (
          <BlockContent
            errors={isSourceLanguageValueNotExist}
            contentStyle={sourceLanguageBlockStyle}
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div styleName={"form.selectSourceLanguageMessage"}>
                  <span>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SOURCE_LANGUAGE_HINT")}</span>
                </div>
                <div styleName={"form.dropdown-menu"}>
                  <DropdownMenu
                    languages={sortedSourceLanguages}
                    tabIndex={tabIndex.selectSourceTextArea}
                    link={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.LINK_DETECT")}
                    placeholder={placeholderMenu}
                    value={sourceLanguage}
                    focus={focusDetectedBtn}
                    handlerChangeLanguage={this.handleChangeSourceLanguage}
                    disableIcon={disableIcon}
                    handlerLanguageDetected={this.handleSourceLanguageDetected}
                    onLanguageChange={this.onSourceLanguageChange}
                    onBlurDetectBtn={this.onBlurDetectBtn}
                    defaultInputValue={searchSourceLanguageInputValue}
                    onSaveInputValue={this.saveSearchSourceLanguageInputValue}
                    menuMarginTop={2}
                  />
                </div>
              </div>
            }
          />
        ),
        sectionWrapper: true,
      },
      {
        id: "target",
        multiSection: true,
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.TARGET_LANGUAGES"),
        containerStyleName: "settingSection.containerLanguages",
        itemsStyleNames: ["settingSection.itemLanguages", "settingSection.nextItemLanguages"],
        sidebarInfo: amountSelectedTargetLanguages,
        isShowPopover: isShowPopover,
        toolbar: targetToolbar,
        component: () => (
          <React.Fragment>
            {targetToolbar && (
              <div styleName={mobile ? "bar.sticky-container-mobile" : "bar.sticky-container"}>{targetToolbar}</div>
            )}
            <div
              ref={languagesBlock => (this.languagesBlock = languagesBlock)}
              styleName={"form.languagesBlock"}
              style={targetLanguageStyle}
            >
              <BlockContent
                errors={targetLanguagesError}
                content={
                  <div styleName={"form.targetLanguages"}>
                    <div styleName={"form.selectTargetLanguagesMessage"}>
                      <span>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SELECT_TARGET_LANGUAGES_MESSAGE")}</span>
                    </div>
                    <TableLanguagesBlock
                      selectedService={this.state.selectedService}
                      languageBlockWidth={languageBlockWidth}
                      tableBlockStyle={tableBlockStyle}
                      sourceLanguage={sourceLanguage}
                      languages={sortedTargetLanguages}
                      selectedTargetLanguage={selectedTargetLanguage}
                      recentPairsList={[]}
                      isLoadingMe={isLoadingMe}
                      handleClick={this.handleSelectTargetLanguage}
                      clickDisabledLanguage={this.clickDisabledLanguage}
                    />
                  </div>
                }
              />
              <div styleName={"form.notAvailableLanguagesMessage"}>
                {
                  <span>
                    {notAvailableLanguagesMessage.leftText}
                    <span>
                      <LinkWrapper
                        tabIndex={5}
                        style={{ fontSize: 14 }}
                        textContent={notAvailableLanguagesMessage.link}
                        onClick={this.onOpenChatForLanguages}
                      />
                    </span>
                    {notAvailableLanguagesMessage.rightText}
                  </span>
                }
              </div>
            </div>
          </React.Fragment>
        ),
        sectionWrapper: true,
      },
      {
        id: "context",
        multiSection: true,
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.HINT_TEXT"),
        titleColor: "#FF9800",
        handlerDragLeave: this.handleDragLeave,
        handlerDragEnter: this.handleDragEnter,
        handleDrop: this.handleAddImage,
        isShowPopover: isShowPopover,
        component: () => (
          <div styleName={"form.hintBlock"} ref={sourceBlock => (this.context = sourceBlock)}>
            <BlockContent
              errors={isNoContextError}
              content={
                <Fragment>
                  <TextareaAutoresize
                    ref={hintInput => (this.hintInput = hintInput)}
                    tabIndex={tabIndex.contextTextArea}
                    areaStyleName={"inputNewOrder"}
                    placeholder={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.PLACEHOLDER_HINT_TEXT")}
                    initValue={sourceHint}
                    textAreaStyle={textAreaStyle}
                    styleContainer={{ marginBottom: "-22px" }}
                    isTemporaryStyles={true}
                    onFocusTextArea={() => {}}
                    onChange={this.handleChangeSourceHint}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      marginTop: 7,
                    }}
                  >
                    <AddImageButton tabindex={tabIndex.contextAddImage} handleAddImage={this.handleAddImage} />
                  </div>
                  {images &&
                    images.length > 0 &&
                    images.map((image, i) => {
                      if (!_.isEmpty(image) && image.uploadImageError) {
                        this.loadingImage();
                        const ref = image === imageLoading ? image => (this.image = image) : "";
                        return (
                          <ImageErrorMessage
                            ref={ref}
                            key={i}
                            image={image}
                            arrayCount={i}
                            handleDeleteImage={this.handlerDeleteImage}
                            handleRetry={this.handleRetry}
                          />
                        );
                      }
                    })}

                  {images && images.length > 0 && (
                    <div styleName={"form.imageList"}>
                      {images.map((image, i) => {
                        if (!_.isEmpty(image) && !image.uploadImageError) {
                          this.loadingImage();
                          const ref = image === imageLoading ? image => (this.image = image) : "";
                          return (
                            <UploadingImageView
                              ref={ref}
                              key={i}
                              image={image}
                              arrayCount={i}
                              displayLoading={!image.id}
                              handleDeleteImage={this.handlerDeleteImage}
                              handleRetry={this.handleRetry}
                            />
                          );
                        }
                      })}
                    </div>
                  )}
                </Fragment>
              }
              contentStyle={hintContentStyle}
            />
          </div>
        ),
        sectionWrapper: true,
        hiddenBlock: {
          id: "advancedSettings",
          text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.TITLE"),
          isShowBlock: true,
          titleColor: "#FF9800",
          component: () => (
            <AdvancedOptions
              savedValue={this.props.tableState.maxTranslationsLength}
              setMaxLength={value => this.handleTranslationLengthChange(value)}
              handleChangeAdvancedSettings={this.handleChangeAdvancedSettings}
              advancedSettingsList={advancedSettingsList}
              localeFormSelectStyle={localeFormSelectStyle}
              advancedSettingsValue={advancedSettingsValue}
              advancedCategoryValue={advancedCategoryValue}
              categorySettingsList={filteredCategorySettingsList}
              handleChangeCategorySettings={this.handleChangeCategorySettings}
            />
          ),
        },
      },
      {
        id: "timing",
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.TIMING"),
        question: newOrderPageLocal("DURATION_HINT"),
        component: () => (
          <div styleName={"form.duration"} style={durationStyle}>
            <BlockContent
              content={
                <div style={{ width: "100%" }}>
                  <p styleName={"form.durationText"}>{I18n._translate("CUSTOMER_APP.NEW_ORDER_PAGE.DURATION_TEXT")}</p>
                  <DeliveryTimeTable
                    files={files}
                    sourceText={sourceTextCounter ? { volume: sourceTextCounter } : null}
                    orderType={orderType}
                    sourceLanguage={sourceLanguage}
                    selectedTargetLanguage={selectedTargetLanguage}
                  />
                </div>
              }
              contentStyle={durationContentStyle}
            />
          </div>
        ),
        sectionWrapper: true,
      },
      {
        id: "cost",
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.COST"),
        getSidebarInfo: this.getSidebarInfoPrice,
        sidebarInfo: priceSidebarInfo,
        question: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PRICE_HINT"),
        component: () => (
          <div styleName={"form.duration"} style={durationStyle}>
            <BlockContent
              content={
                <div style={{ width: "100%" }}>
                  <PriceTableWithoutAuth
                    orderType={this.props.tableState.orderType}
                    files={files}
                    sourceText={sourceTextCounter ? { volume: sourceTextCounter } : null}
                    filesTotalPrice={filesTotalPrice}
                    discount={discount}
                    sourceLanguage={sourceLanguage}
                    formatCurrency={this.formatCurrencyForTablePrice}
                    discountsTMList={discountsTMList}
                    selectedTargetLanguage={selectedTargetLanguage}
                    handleBtnCalculateWithoutAuth={this.handleBtnCalculateWithoutAuth}
                    showBtnCalculate={this.state.showBtnCalculate}
                    page={"withoutAuth"}
                  />
                  {this.renderDiscount()}
                </div>
              }
              contentStyle={durationContentStyle}
            />
          </div>
        ),
        sectionWrapper: true,
      },
      {
        multiSection: true,
        id: "balance",
        text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.BALANCE"),
        getSidebarInfo: this.getSidebarInfoPrice,
        sidebarInfo: false,
        components: [
          {
            id: "balance",
            text: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.BALANCE"),
            question: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.BALANCE_HINT"),
            component: () => (
              <div styleName={"form.priceBlock"} style={priceStyle}>
                <BlockContent
                  errors={insufficientFundsError}
                  content={
                    <div className={"form.priceContainer"}>
                      <div styleName={"form.priceContent"}>
                        <span styleName={"form.priceText"}>{currencyAvailableWithSymbol}</span>
                        <IcomoonReact
                          iconSet={iconSet}
                          color={"#1E88E5"}
                          size={10}
                          icon={"add_circle"}
                          styleName={"form.addFundsButton"}
                          title={I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.SECTION_TITLE")}
                          onClick={this.onShowAddFundsBlock}
                        />
                      </div>
                    </div>
                  }
                  contentStyle={{}}
                />
              </div>
            ),
          },
        ],
        hiddenBlock: {
          id: "addFunds",
          text: I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.SECTION_TITLE"),
          isShowBlock: isShowAddFundsBlock,
          isShowPopover: isShowPopover,
          onCloseBlock: this.onCloseAddFundsBlock,
          component: () => (
            <div styleName={"form.addFunds"} ref={addFundBlock => (this.addFundBlock = addFundBlock)} style={addFunds}>
              <div styleName={"newOrder.menu-container"}>
                <div styleName={"newOrder.menu-label"}>
                  {I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.PAYMENT_METHOD")}
                </div>
                <DefaultSelect
                  id="paymentMethod"
                  handleAddNewPaymentMethodClick={this.handleAddNewCardClick}
                  selectStyle={addFundsFormSelectStyle}
                  selectOptionStyle={addFundsFormSelectOption}
                  value={paymentMethodValue}
                  onChange={this.handlePaymentMethodChange}
                  options={paymentSelection}
                />
              </div>
              {couponDescriptionDisplay && (
                <div styleName={"newOrder.couponDescription"}>
                  {I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.COUPON_DESCRIPTION")}
                </div>
              )}

              <div styleName={"newOrder.amount-container"}>
                <FormInput
                  onChange={this.handleAmountChange}
                  id="amount"
                  type="text"
                  value={amount}
                  styleInput={{ width: 142 }}
                  label={couponLabel}
                />
                {this.state.equivalentDisplay && (
                  <div styleName={"newOrder.equivalent"}>
                    {I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.EQUIVALENT", {
                      equivalent: equivalent,
                    })}
                  </div>
                )}
              </div>

              <div styleName={"newOrder.button-container"}>
                <NitroButton
                  clickHandler={this.createOrder}
                  buttonStyleName={"button.save-button"}
                  style={{ minWidth: 115 }}
                  title={buttonLabel}
                />
              </div>
            </div>
          ),
        },
      },
    ];

    if (isProofreadService) {
      supportBlocks = supportBlocks.filter(block => {
        return block.id !== "target";
      });
    }

    const balance = settingsBalance({
      balance: { integer: coupon.amount, decimal: 0 },
      reserved: 0,
    });

    return (
      <div styleName={"baseContainer.new-order baseContainer.unauth-new-order"}>
        <UnderHeader auth={true}>
          <SettingsToolbar
            title={newOrderPageLocal("PAGE_HEADING")}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 18,
              width: "100%",
              justifyContent: "space-between",
            }}
            titleStyle={{ width: "33%" }}
          >
            {!_.isEmpty(coupon) &&
              (coupon.status === "valid" ||
                (coupon.status === "redeemed" &&
                  (coupon.policy === CouponPolicy.MULTI || coupon.policy === CouponPolicy.MULTI_NEW_ACCOUNTS))) && (
                <div
                  style={{
                    display: "flex",
                    width: "33%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CustomerBadge
                    content={{
                      value: currencyAvailableWithSymbol,
                      positiveValue: balance.positiveValue,
                    }}
                  />
                </div>
              )}
            <div styleName={"newOrder.headerButtons"}></div>
          </SettingsToolbar>
        </UnderHeader>

        <ScrollNavigator
          ref={nav => (this.nav = nav)}
          isUnauth={true}
          navigationBlocks={supportBlocks}
          additionalSidebarBlock={additionalSidebarBlock}
          isReverseScrollNavigatorBlocks={true}
          isAddNumberBlockToSidebar={true}
          hiddenSidebarForSmallDisplay={true}
          sideBarCustomStyle={"scrollNavigator.adaptive-hidden-revers"}
          scrollTo={scrollToElementId}
          onClearScrollItem={this.onClearScrollItem}
          maxWidth={644}
          scrollToPosition={scrollPosition}
          sidebarTop={sidebarTop}
        />
        <div
          styleName={
            "order.before-scroll-navigator-placement order.unauth order.before-scroll-navigator-placement-unauth-mobile"
          }
          ref={messageLink => (this.messageLink = messageLink)}
        >
          <HtmlTranslate
            value={"CUSTOMER_APP.NEW_ORDER_PAGE.HAVE_LARGE_TRANSLATION_CONTACT_PM_LINK"}
            id={"beforeContactManager"}
            idBlank={"beforeBlankContactManager"}
          />
        </div>
        {googleSheetsData && (
          <CheckBoxPopup
            isOpen={popupCheckbox}
            onClose={this.hendleCheckboxPopupClose}
            googleSheetsData={googleSheetsData}
            googleSheetsDataFileName={googleSheetsDataFileName}
            googleSheetsLink={googleSheetsLink}
            googleDriveUrl={googleDriveUrl}
            changeTableState={changeTableState}
            handleUploadGoogleFile={this.handleUploadGoogleFile}
            loadingView={this.state.loadingGoogleSheetTable}
          />
        )}
        <PopupFinishLater
          click={this.handleFinishLaterSubmitClick}
          isOpened={this.state.isPopupFinishLaterOpened}
          setIsOpened={this.setIsPopupFinishLaterOpened}
        />
        {uploadFilePopup && (
          <UploadFilePopup
            fileElements={uploadFileElements}
            fileName={files[0].filename}
            fileType={files[0].type}
            fileText={files[0].filesAsFormattedText}
            isOpen={uploadFilePopup}
            onClose={this.handleCloseUploadFilePopup}
            onCreateOrder={this.uploadFile}
          />
        )}
        {files[0] && files[0].content && filePreviewPopup && (
          <PopupPreview
            isOpen={filePreviewPopup}
            onClose={this.handleClosePreviewPopup}
            sourceLanguage={sourceLanguage}
            file={files[0]}
            setFile={this.setFile}
            setCharsAmount={this.setSourceSectionAndSidebarCharsAmount}
            handleChangeSourceLanguage={this.handleSourceLanguageDetected}
          />
        )}
        {files[0] && files[0].type === "CSV" && (
          <PopupCSVSettings
            file={files[0]}
            isOpen={uploadCSVSettingsPopup}
            onClose={this.handleCloseCSVSettingsPopup}
            isErrorWithDefaultParserInfo={uploadFileDefaultParserInfoError}
            parserInfo={uploadFileParserInfo}
            setParserInfo={this.setParserInfo}
            setFileElements={this.setUploadFileElements}
            uploadFileFromPopup={this.uploadFileFromPopup}
            openCSVSelectorPopup={this.handleOpenCSVSelectorPopup}
          />
        )}
        {files[0] && files[0].type === "CSV" && (
          <PopupCSVSelector
            isOpen={uploadCSVSelectorPopup}
            onClose={this.handleCloseCSVSelectorPopup}
            file={files[0]}
            fileElements={uploadFileElements}
            setFileElements={this.setUploadFileElements}
            parserInfo={uploadFileParserInfo}
            setParserInfo={this.setParserInfo}
            onCreateOrder={this.uploadFile}
            isUploadCSVLoading={isUploadCSVLoading}
          />
        )}
        <PopupDialog
          isOpen={dialogOpen}
          title={dialogTitle}
          text={textAcceptCreateOrder}
          textAccept={textAccept}
          acceptBtnStyle={"green"}
          textClose={textClose}
          onAccept={this.handlePopupAccept}
          onClose={this.handlePopupClose}
        />

        <PopupDialog
          isOpen={dialogSignInOpen}
          onClose={this.handlePopupClose}
          styleContainer={{
            display: "flex",
            flexDirection: "column",
            width: 360,
          }}
          stylePopup={{ overflow: "scroll" }}
          styleTitle={{
            color: "#424242",
            fontSize: 18,
            letterSpacing: 0,
            paddingRight: 8,
            marginBottom: 24,
          }}
          title={loginPopupTitle}
          isIconClose={true}
        >
          {this.props.children}
          <Outlet />
        </PopupDialog>

        <PopupDialog
          isOpen={this.state.uploadGoogleDrivePopupShow || this.state.uploadGoogleSheetsPopupShow}
          title={
            this.state.uploadGoogleSheetsPopupShow
              ? I18n.t("UPLOAD_FILES_POPUP.SHEETS_TITLE")
              : I18n.t("UPLOAD_FILES_POPUP.TITLE")
          }
          text={
            <div styleName={"form.uploadInputContainer"}>
              {this.state.isLongSpreadsheetLoading ? (
                I18n.t("UPLOAD_FILES_POPUP.LONG_LOADING_SPREADSHEET")
              ) : (
                <FormInput
                  focus={true}
                  status={
                    this.state.typeGoogleFile === "document"
                      ? this.state.googleDriveUrlStatus
                      : this.state.typeGoogleFile === "spreadsheets"
                        ? this.state.googleSheetsUrlStatus
                        : this.state.uploadGoogleSheetsPopupShow
                          ? this.state.googleSheetsUrlStatus
                          : this.state.googleDriveUrlStatus
                  }
                  onChange={this.onChangeGoogleDrive}
                  value={this.state.googleDriveUrl}
                  label={I18n.t("UPLOAD_FILES_POPUP.INPUT_LABEL")}
                  styleInput={{ width: "100%", margin: 0, marginTop: 8 }}
                  styleContainer={{ margin: 0 }}
                />
              )}
            </div>
          }
          textAccept={I18n.t("BUTTONS.ADD")}
          acceptBtnStyle={"blue"}
          acceptDisabled={!this.state.googleDriveUrl}
          styleContainer={{ maxWidth: 432 }}
          textClose={I18n.t("CUSTOMER_APP.SETTINGS_PAGE.POPUP.BTN_CANCEL")}
          onAccept={this.handleUploadGoogleDrivePopupAccept}
          onClose={this.handleUploadGoogleDrivePopupClose}
          loadingView={this.state.loadingGoogleSource}
          btnAdd={true}
          closeButtonStyle={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            color: "#9E9E9E",
          }}
          styleTitle={{
            marginBottom: 8,
          }}
        />
      </div>
    );
  }
}

export default withRouter(withWebsocket(NewOrderGeneralWithoutAuth));
